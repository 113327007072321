import React, { Component } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { OrderGridContext } from ".";
import { isAllowedReducedGridView } from "../../../../services/common/util";
import { Link } from "react-router-dom";

class OrderBreadcrumb extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="breadcrumb-bar">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-8 col-12">
							<nav aria-label="breadcrumb" className="page-breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/">Home</Link>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Orders
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Completed Orders
									</li>
								</ol>
							</nav>
							<div
								style={{ display: "flex", }}
							>
								<h2 className="breadcrumb-title mr-3">Completed Orders</h2>
								<FormControlLabel
									value="includeArchived"
									control={<Switch checked={this.props.isArchived} onChange={this.props.onIsArchivedInformation} />}
									label="Show Archives"
									labelPlacement="end"
									className="m-0 show-archives-control breadcrumb-control"
								/>
								{
									isAllowedReducedGridView('order') &&
									<OrderGridContext.Consumer>
										{({ isReducedGridView, onIsReducedGridView }) =>
											<FormControlLabel
												value="isReducedGridView"
												control={<Switch checked={isReducedGridView} onChange={onIsReducedGridView} />}
												label={isReducedGridView ? "Reduced Grid View" : "Full Grid View"}
												labelPlacement="end"
												className="m-0 show-reduced-control"
											/>
										}
									</OrderGridContext.Consumer>
								}
							</div>
						</div>
						<div className="col-md-4 col-12">
							<div className="row breadcrumb-btns">
								<label className="breadcrumb-text" >DownLoads : </label>
								<ul className="export-ul">
									<li onClick={() => this.props.onBtnExport()}>Export Grid</li>
									<li onClick={() => this.props.onBtnExportZipForRequisitions()}>Bulk Requisition</li>
									<li onClick={() => this.props.onBtnExportZipForResults()}>Bulk Results</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OrderBreadcrumb;
