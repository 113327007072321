import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import SearchMenu from "./searchMenu";
import { getUserRole, getUserDetails } from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import { fetchAnalytes } from "../../../../services/clinicPortalServices/analyteService";
import moment from "moment";

class ClinicAnalyteGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnDefs: [
                {
                    headerName: "Actions",
                    minWidth: 150,
                    maxWidth: 150,
                    cellStyle: { textAlign: "center" },
                    cellRenderer: "editBtnCellRenderer"
                },
                {
                    headerName: "Name",
                    minWidth: 150,
                    resizable: true,
                    field: "name"
                },
                {
                    headerName: "Loinc Code",
                    minWidth: 150,
                    resizable: true,
                    field: "loincCode"
                },
                {
                    headerName: "Units",
                    minWidth: 150,
                    resizable: true,
                    field: "units"
                },
                {
                    headerName: "Specimen Type",
                    minWidth: 150,
                    resizable: true,
                    field: "specimenType"
                },
                {
                    headerName: "Sample Type",
                    minWidth: 150,
                    resizable: true,
                    field: "sampleType"
                },
                {
                    headerName: "Result Type",
                    minWidth: 150,
                    resizable: true,
                    field: "resultType"
                },
                // {
                //     headerName: "Reference Ranges",
                //     minWidth: 150,
                //     resizable: true,
                //     field: "referenceRanges"
                // },
                // {
                //     headerName: "Critical Values",
                //     minWidth: 150,
                //     resizable: true,
                //     field: "criticalValues"
                // },
                {
                    headerName: "Method",
                    minWidth: 150,
                    resizable: true,
                    field: "method"
                },
                {
                    headerName: "Instrument",
                    minWidth: 150,
                    resizable: true,
                    field: "instrument"
                },
                {
                    headerName: "Turnaround Time",
                    minWidth: 150,
                    resizable: true,
                    field: "turnaroundTime"
                },
                {
                    headerName: "Cost",
                    minWidth: 150,
                    resizable: true,
                    field: "cost"
                },
                {
                    headerName: "CPT Codes",
                    minWidth: 150,
                    resizable: true,
                    field: "cptCodes"
                },
                {
                    headerName: "Clinical Significance",
                    minWidth: 150,
                    resizable: true,
                    field: "clinicalSignificance"
                },
                {
                    headerName: "Storage / Transport",
                    minWidth: 150,
                    resizable: true,
                    field: "storageTransport"
                },
                {
                    headerName: "Synonyms",
                    minWidth: 150,
                    resizable: true,
                    field: "synonyms"
                },
                {
                    headerName: "Interferences",
                    minWidth: 150,
                    resizable: true,
                    field: "interferences"
                }
            ],
            components: {
                editBtnCellRenderer: EditBtnCellRenderer
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            rowData: [],
            context: { componentParent: this }
        };
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadGridData();
    };

    loadGridData = () => {
        fetchAnalytes().then((res) => {
            this.setState({ rowData: res.data });
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    clearFilter = () => {
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "Analyte_Grid_" +
                moment().format("YYYY_MM_DD_HH_mm_ss") +
                ".xlsx"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.AnalyteGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.AnalyteGridExported
        };
        createAudit(auditData);
    };

    render() {
        let columnDefs = /salesperson|audit|salesadmin/.test(
            getUserRole().toLowerCase()
        )
            ? this.state.columnDefs.filter((x) => x.headerName !== "Actions")
            : this.state.columnDefs;
        return (
            <div className="clinic-contain">
                <SearchMenu
                    onFilterTextChange={this.onFilterTextChange}
                    clearFilter={this.clearFilter}
                    onBtnExport={this.onBtnExport}
                    context={this.state.context}
                />
                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 250px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    pagination={true}
                                    paginationAutoPageSize={true}
                                    components={this.state.components}
                                    context={this.state.context}
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default ClinicAnalyteGrid;
