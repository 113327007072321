import React, { Component } from "react";
import DashboardSearchbar from "./dashboardSearchbar";
import moment from "moment";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import _ from "lodash";
import {
    searchOrdersDashboard,
    searchTopFacilities
} from "../../../../services/clinicPortalServices/orderSearchService";
import { searchResultLinksDashboard } from "../../../../services/clinicPortalServices/orderEditService";
import { searchInvoicesDashboard } from "../../../../services/clinicPortalServices/invoiceServices";
import { getUserRole } from "../../../../services/common/util";
import ProgressBar from "react-customizable-progressbar";
import { Icon01, Icon02, Icon03 } from "./img";
import Chart from "react-apexcharts";
import {
    Paper,
    MenuList,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { FadeLoader } from "react-spinners";
import { loaderPositionStyle } from "../../../../services/common/optionsData";
import { Link } from "react-router-dom";
import { getAllTests } from "../../../../services/clinicPortalServices/testService";

class ClinicDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingOrders: true,
            isLoadingTopFacilities: false,
            isLoadingResultLinks: false,
            isLoadingInvoices: false,
            hl7MrnOptions: {
                labels: ["HL7 Orders", "BlueLot Created Orders"],
                colors: ["#ea5455", "#00cfe8"],
                legend: {
                    show: true,
                    position: "top"
                },
                tooltip: {
                    enabled: false
                },
                dataLabels: {
                    enabled: false
                }
            },
            hl7MrnSeries: [40000, 50000],
            series: [1, 0],
            insuranceOptions: {
                colors: ["#28c76fa1", "#28c76f"],
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                labels: ["Insurances", "No insurances"],
                tooltip: {
                    enabled: false
                }
            },

            isArchived: false,
            facilityIds: [],
            user_role: getUserRole(),
            search_filters: {
                facility_id: [],
                from_date:
                    moment().subtract(3, "days").format("YYYY-MM-DD") +
                    "T00:00",
                to_date:
                    moment().add(1, "days").format("YYYY-MM-DD") + "T00:00",
                dateRange: "custom",
                filter_by: "released_date"
            },
            facilities: [],
            resultLinksAll: 0,
            resultLinksUsed: 0,
            invoicesOutstanding: 0,
            invoicesPaid: 0,
            topFacilities: [],
            topFacilitiesDefault: [],
            hl7Orders: 0,
            bluelotOrders: 0,
            inconclusiveOrders: 0,
            negativeOrders: 0,
            positiveOrders: 0,
            totalOrders: 0,
            insuranceOrders: 0,
            nonInsuranceOrders: 0,
            samplesNotReceieved: 0,
            samplesCancelled: 0
        };
        getAllTests({ type: "all" }).then((res) => {
            if (res && res.data) {
                let testIds = res.data.map((test) => {
                    return test._id;
                });
                this.testIds = testIds;
                this.setState({ tests: res.data, testIds: testIds }, () => {
                    this.loadFacilities(this.state.isArchived);
                });
            }
        });
    }

    loadFacilities = (isArchived) => {
        let defaultFilters = this.state.search_filters;
        let facilities = this.state.facilities;
        let facilityIds = [];

        let isAllFacilities = false;
        if (
            defaultFilters.facility_id &&
            defaultFilters.facility_id.length > 0 &&
            defaultFilters.facility_id.length === facilities.length &&
            facilities.length > 0
        ) {
            isAllFacilities = true;
        }

        getCompanyWithFacility(isArchived).then((response) => {
            const facilities = response.data;
            const topFacilitiesDefault = facilities
                .filter((facility) => facility.type === "facility")
                .slice(0, 10);
            if (facilities.length > 0) {
                facilities.map((fac) => {
                    facilityIds.push(fac._id);
                    return null;
                });
                defaultFilters.facility_id = facilityIds;
            } else {
                defaultFilters.facility_id = facilityIds;
            }
            if (
                !isAllFacilities &&
                window.localStorage.getItem("FACILITY_ID") &&
                JSON.parse(window.localStorage.getItem("FACILITY_ID")).length
            ) {
                defaultFilters.facility_id = JSON.parse(
                    window.localStorage.getItem("FACILITY_ID")
                );
            }
            this.setState(
                {
                    facilities: facilities,
                    topFacilitiesDefault: topFacilitiesDefault,
                    search_filters: defaultFilters,
                    facilityIds: facilityIds
                },
                () => {
                    this.loadData();
                }
            );
        });
    };

    checkRealFacilities = (searchFilters) => {
        let realFacilities = [];
        searchFilters.facility_id.map((facilityId) => {
            if (this.state.facilityIds.includes(facilityId)) {
                realFacilities.push(facilityId);
            }
            return null;
        });
        return realFacilities;
    };

    loadData = () => {
        this.setState({
            isLoadingOrders: true,
            isLoadingTopFacilities: true,
            isLoadingResultLinks: true,
            isLoadingInvoices: true
        });
        let searchFilters = this.state.search_filters;
        searchFilters.facility_id = this.checkRealFacilities(searchFilters);
        searchFilters.testIds = this.state.testIds;
        searchOrdersDashboard(searchFilters).then((response) => {
            const orders = response.data;
            const enableLabelsHl7 =
                orders.hl7Orders > 0 || orders.bluelotOrders > 0 ? true : false;
            this.setState({
                ...response.data,
                isLoadingOrders: false,
                hl7MrnOptions: {
                    ...this.state.hl7MrnOptions,
                    tooltip: {
                        enabled: enableLabelsHl7 ? true : false
                    },
                    dataLabels: {
                        enabled: enableLabelsHl7 ? true : false
                    }
                },
                insuranceOptions: {
                    ...this.state.insuranceOptions,
                    tooltip: {
                        enabled:
                            orders.insuranceOrders > 0 ||
                            orders.nonInsuranceOrders > 0
                                ? true
                                : false
                    }
                }
            });
        });
        searchTopFacilities(searchFilters).then((response) => {
            this.setState({
                ...response.data,
                isLoadingTopFacilities: false
            });
        });
        searchResultLinksDashboard(searchFilters).then((response) => {
            this.setState({
                ...response,
                isLoadingResultLinks: false
            });
        });
        searchInvoicesDashboard(searchFilters).then((response) => {
            this.setState({
                ...response.data,
                isLoadingInvoices: false
            });
        });
    };

    onIsArchivedInformation = (event) => {
        this.setState({ isArchived: event.target.checked });
        this.loadFacilities(event.target.checked);
    };

    handleFiltersChange = (e) => {
        let { facilityIds, facilities } = this.state;
        let filters = this.state.search_filters;
        switch (e.target.name) {
            case "filter_by": {
                filters.filter_by = e.target.value;
                break;
            }
            case "facility_id": {
                let facilityIdChanged = [],
                    isAdded = false,
                    facilitiesIn = [],
                    facilityIdsTemp = [];
                let newFacilityIds = e.target.value;
                if (e.target.value.includes("all")) {
                    if (filters.facility_id.length === facilityIds.length) {
                        filters.facility_id = [];
                    } else {
                        filters.facility_id = _.cloneDeep(facilityIds);
                    }
                } else {
                    if (filters.facility_id.length > newFacilityIds.length) {
                        facilityIdChanged = filters.facility_id.find(
                            (val) => !newFacilityIds.includes(val)
                        );
                    } else {
                        facilityIdChanged = newFacilityIds.find(
                            (val) => !filters.facility_id.includes(val)
                        );
                        isAdded = true;
                    }
                    let facilityChanged = facilities.find(
                        (facility) => facility._id === facilityIdChanged
                    );
                    facilityIdsTemp = _.cloneDeep(filters.facility_id);
                    // If company is changed
                    if (facilityChanged.type === "company") {
                        let companyChanged = facilityChanged;
                        facilities.forEach((fac) => {
                            if (fac.parent_id === companyChanged._id) {
                                facilitiesIn.push(fac._id);
                            }
                        });
                        let index = facilityIdsTemp.indexOf(companyChanged._id);
                        if (facilitiesIn.length > 0) {
                            // If company is selected
                            if (isAdded) {
                                // Add company Id
                                facilityIdsTemp.push(companyChanged._id);
                                // Add facility Ids
                                facilitiesIn.forEach((facId) => {
                                    if (!facilityIdsTemp.includes(facId)) {
                                        facilityIdsTemp.push(facId);
                                    }
                                });
                            } else {
                                // If company is unselected
                                // Remove company Id
                                if (index > -1) {
                                    facilityIdsTemp.splice(index, 1);
                                }
                                facilitiesIn.forEach((facId) => {
                                    let index = facilityIdsTemp.indexOf(facId);
                                    if (index > -1) {
                                        facilityIdsTemp.splice(index, 1);
                                    }
                                });
                            }
                        } else {
                            isAdded
                                ? facilityIdsTemp.push(companyChanged._id)
                                : facilityIdsTemp.splice(index, 1);
                        }
                    } else {
                        // If facility is changed
                        let companyId = facilityChanged.parent_id;
                        let facilitiesIn = [];
                        facilities.forEach((fac) => {
                            if (fac.parent_id === companyId) {
                                facilitiesIn.push(fac._id);
                            }
                        });
                        // If facility Id is selected
                        if (isAdded) {
                            // Add facility Id
                            facilityIdsTemp.push(facilityChanged._id);
                            if (!filters.facility_id.includes(companyId)) {
                                facilityIdsTemp.push(companyId);
                            }
                        } else {
                            // If facility Id is unselected
                            // Remove facility Id
                            let index = facilityIdsTemp.indexOf(
                                facilityChanged._id
                            );
                            if (index > -1) {
                                facilityIdsTemp.splice(index, 1);
                            }
                            // Remove company Id
                            let facilitiesInFilters = [];
                            facilitiesIn.forEach((fac) => {
                                if (facilityIdsTemp.includes(fac))
                                    facilitiesInFilters.push(fac);
                            });
                            if (facilitiesInFilters.length === 0) {
                                index = facilityIdsTemp.indexOf(companyId);
                                if (index > -1) {
                                    facilityIdsTemp.splice(index, 1);
                                }
                            }
                        }
                    }
                    filters.facility_id = _.cloneDeep(facilityIdsTemp);
                    window.localStorage.setItem(
                        "FACILITY_ID",
                        JSON.stringify(filters.facility_id)
                    );
                }
                break;
            }
            case "testIds": {
                let testIds = this.state.testIds;
                if (e.target.value.includes("all")) {
                    if (testIds.length === this.state.tests.length) {
                        testIds = [];
                    } else {
                        testIds = _.cloneDeep(this.testIds);
                    }
                } else {
                    testIds = e.target.value;
                }
                this.setState({ testIds: testIds }, () => {
                    this.loadData(this.state.isArchived);
                });
                break;
            }
            default:
                break;
        }
        this.setState({ search_filters: filters });
        if (e.target.name !== "testIds") {
            this.loadData();
        }
    };

    updateDateRange = (dateRange) => {
        let filters = this.state.search_filters;
        if (filters.dateRange !== dateRange) {
            switch (dateRange) {
                case "today":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().format("YYYY-MM-DD") + "T00:00";
                    filters.dateRange = dateRange;
                    break;
                case "week":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("week").format("YYYY-MM-DD") +
                        "T00:00";
                    filters.dateRange = dateRange;
                    break;
                case "month":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("month").format("YYYY-MM-DD") +
                        "T00:00";
                    filters.dateRange = dateRange;
                    break;
                default:
                    break;
            }
            this.setState({ search_filters: filters });
            if (dateRange !== "custom") {
                this.loadData();
            }
        }
    };

    setDateRange = (searchFilter) => {
        if (
            moment(searchFilter.to_date).format("YYYY-MM-DD") ===
            moment().add(1, "days").format("YYYY-MM-DD")
        ) {
            if (
                moment(searchFilter.from_date).format("YYYY-MM-DD") ===
                moment().format("YYYY-MM-DD")
            ) {
                searchFilter.dateRange = "today";
            } else if (
                moment(searchFilter.from_date).format("YYYY-MM-DD") ===
                moment().startOf("week").format("YYYY-MM-DD")
            ) {
                searchFilter.dateRange = "week";
            } else if (
                moment(searchFilter.from_date).format("YYYY-MM-DD") ===
                moment().startOf("month").format("YYYY-MM-DD")
            ) {
                searchFilter.dateRange = "month";
            } else {
                searchFilter.dateRange = "custom";
            }
        } else {
            searchFilter.dateRange = "custom";
        }
    };

    handleDateFiltersChange = (dateTime, type) => {
        const filters = this.state.search_filters;
        let filterIsValid = true;
        if (type === "from_date") {
            if (moment(filters.to_date).isAfter(dateTime)) {
                filters.from_date = dateTime;
            } else {
                filterIsValid = false;
            }
        } else {
            if (moment(dateTime).isAfter(filters.from_date)) {
                filters.to_date = dateTime;
            } else {
                filterIsValid = false;
            }
        }
        if (filterIsValid) {
            this.setDateRange(filters);
            this.setState({ search_filters: filters });
            this.loadData();
        }
    };

    clearFilter = () => {
        let filters = this.state.search_filters;
        filters.facility_id = this.state.facilityIds;
        filters.from_date =
            moment().subtract(3, "days").format("YYYY-MM-DD") + "T00:00";
        filters.to_date =
            moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
        filters.filter_by = "received_date";
        filters.dateRange = "custom";

        window.localStorage.removeItem("FACILITY_ID");
        window.localStorage.removeItem("FROM_DATE_RANGE");
        window.localStorage.removeItem("TO_DATE_RANGE");

        this.setState({ search_filters: filters, testIds: this.testIds });
        this.loadData();
    };

    render() {
        const {
            facilities,
            search_filters,
            isLoadingInvoices,
            isLoadingResultLinks,
            isLoadingTopFacilities,
            isLoadingOrders
        } = this.state;
        const topFacilities =
            this.state.topFacilities.length > 0
                ? this.state.topFacilities
                : this.state.topFacilitiesDefault;
        return (
            <div className="clinic-contain">
                <FadeLoader
                    loading={
                        isLoadingInvoices ||
                        isLoadingResultLinks ||
                        isLoadingTopFacilities ||
                        isLoadingOrders
                    }
                    height={12}
                    width={5}
                    radius={10}
                    margin={2}
                    css={loaderPositionStyle}
                    color={"var(--primary-background-active-color)"}
                />
                <DashboardSearchbar
                    onIsArchivedInformation={(event) => {
                        this.onIsArchivedInformation(event);
                    }}
                    isArchived={this.state.isArchived}
                    facilities={facilities}
                    facilityId={search_filters.facility_id}
                    from_date={search_filters.from_date}
                    to_date={search_filters.to_date}
                    date_range={search_filters.dateRange}
                    filter_by={search_filters.filter_by}
                    onFilterTextChange={this.onFilterTextChange}
                    handleFiltersChange={this.handleFiltersChange}
                    handleDateFiltersChange={this.handleDateFiltersChange}
                    updateDateRange={this.updateDateRange}
                    clearFilter={this.clearFilter}
                    onPageSizeChanged={this.onPageSizeChanged}
                    onBtnExport={this.onBtnExport}
                    user_role={this.state.user_role}
                    tests={this.state.tests}
                    testIds={this.state.testIds}
                />
                <div
                    style={{
                        width: "100%",
                        padding: "15px"
                    }}
                >
                    <div className="row">
                        <div className="col-md-12 col-lg-5">
                            <div className="card dash-card">
                                <div className="card-body">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexDirection: "column"
                                            }}
                                        >
                                            <h3 className="text-dash mb-1">
                                                Orders
                                            </h3>
                                            <div>
                                                <p className="text-muted mb-0">
                                                    {moment(
                                                        this.state
                                                            .search_filters
                                                            .from_date
                                                    ).format(
                                                        "MM/DD/YYYY hh:mm A"
                                                    )}{" "}
                                                    -{" "}
                                                    {moment(
                                                        this.state
                                                            .search_filters
                                                            .to_date
                                                    ).format(
                                                        "MM/DD/YYYY hh:mm A"
                                                    )}
                                                </p>
                                                <h2
                                                    className=""
                                                    style={{
                                                        color: "var(--primary-background-active-color)"
                                                    }}
                                                >
                                                    <strong>
                                                        {this.state.totalOrders}
                                                    </strong>
                                                </h2>
                                                <Link
                                                    className="btn btn-primary"
                                                    to="/clinic/orders"
                                                >
                                                    View Orders
                                                </Link>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    textAlign: "center",
                                                    color: "#5e5873"
                                                }}
                                            >
                                                <h4 className="mb-0 text-dash">
                                                    <strong>
                                                        {this.state
                                                            .insuranceOrders
                                                            ? Math.round(
                                                                  (this.state
                                                                      .insuranceOrders /
                                                                      this.state
                                                                          .totalOrders) *
                                                                      100
                                                              )
                                                            : 0}
                                                    </strong>
                                                    %
                                                </h4>
                                                <div
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    Insurance
                                                </div>
                                            </div>
                                            <Chart
                                                options={
                                                    this.state.insuranceOptions
                                                }
                                                series={
                                                    this.state.insuranceOrders
                                                        ? [
                                                              this.state
                                                                  .insuranceOrders,
                                                              this.state
                                                                  .nonInsuranceOrders
                                                          ]
                                                        : [100, 0]
                                                }
                                                type="donut"
                                                width={200}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-7">
                            <div className="row" style={{ height: "100%" }}>
                                <div className="col-md-12">
                                    <div className="card dash-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <h3 className="col-12 text-dash mb-3">
                                                    SARS-CoV-2 Results
                                                </h3>
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="dash-widget dct-border-rht">
                                                        <ProgressBar
                                                            width={20}
                                                            radius={100}
                                                            progress={
                                                                this.state
                                                                    .negativeOrders
                                                                    ? (this
                                                                          .state
                                                                          .negativeOrders /
                                                                          (this
                                                                              .state
                                                                              .negativeOrders +
                                                                              this
                                                                                  .state
                                                                                  .positiveOrders +
                                                                              this
                                                                                  .state
                                                                                  .inconclusiveOrders)) *
                                                                      100
                                                                    : 0
                                                            }
                                                            rotate={-210}
                                                            strokeWidth={16}
                                                            strokeColor="#da3f81"
                                                            strokeLinecap="square"
                                                            trackStrokeWidth={8}
                                                            trackStrokeColor="#e6e6e6"
                                                            trackStrokeLinecap="square"
                                                            pointerRadius={0}
                                                            initialAnimation={
                                                                true
                                                            }
                                                            transition="1.5s ease 0.5s"
                                                            trackTransition="0s ease"
                                                        >
                                                            <div className="indicator-volume">
                                                                <img
                                                                    src={Icon01}
                                                                    className="img-fluid sars-results-icon"
                                                                    alt="Patient"
                                                                />
                                                            </div>
                                                        </ProgressBar>
                                                        <div className="dash-widget-info">
                                                            <h6>Negative</h6>
                                                            <span
                                                                className="text-muted"
                                                                style={{
                                                                    fontSize:
                                                                        "14px"
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .negativeOrders
                                                                }
                                                            </span>
                                                            <h4>
                                                                {this.state
                                                                    .negativeOrders
                                                                    ? Math.round(
                                                                          (this
                                                                              .state
                                                                              .negativeOrders /
                                                                              (this
                                                                                  .state
                                                                                  .negativeOrders +
                                                                                  this
                                                                                      .state
                                                                                      .positiveOrders +
                                                                                  this
                                                                                      .state
                                                                                      .inconclusiveOrders)) *
                                                                              10000
                                                                      ) / 100
                                                                    : 0}
                                                                %
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="dash-widget dct-border-rht">
                                                        <ProgressBar
                                                            width={20}
                                                            radius={100}
                                                            progress={
                                                                this.state
                                                                    .positiveOrders
                                                                    ? (this
                                                                          .state
                                                                          .positiveOrders /
                                                                          (this
                                                                              .state
                                                                              .negativeOrders +
                                                                              this
                                                                                  .state
                                                                                  .positiveOrders +
                                                                              this
                                                                                  .state
                                                                                  .inconclusiveOrders)) *
                                                                      100
                                                                    : 0
                                                            }
                                                            rotate={-210}
                                                            strokeWidth={16}
                                                            strokeColor="#68dda9"
                                                            strokeLinecap="square"
                                                            trackStrokeWidth={8}
                                                            trackStrokeColor="#e6e6e6"
                                                            trackStrokeLinecap="square"
                                                            pointerRadius={0}
                                                            initialAnimation={
                                                                true
                                                            }
                                                            transition="1.5s ease 0.5s"
                                                            trackTransition="0s ease"
                                                        >
                                                            <div className="indicator-volume">
                                                                <img
                                                                    src={Icon02}
                                                                    className="img-fluid sars-results-icon"
                                                                    alt="Patient"
                                                                />
                                                            </div>
                                                        </ProgressBar>
                                                        <div className="dash-widget-info">
                                                            <h6>Positive</h6>
                                                            <span
                                                                className="text-muted"
                                                                style={{
                                                                    fontSize:
                                                                        "14px"
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .positiveOrders
                                                                }
                                                            </span>
                                                            <h4>
                                                                {this.state
                                                                    .positiveOrders
                                                                    ? Math.round(
                                                                          (this
                                                                              .state
                                                                              .positiveOrders /
                                                                              (this
                                                                                  .state
                                                                                  .negativeOrders +
                                                                                  this
                                                                                      .state
                                                                                      .positiveOrders +
                                                                                  this
                                                                                      .state
                                                                                      .inconclusiveOrders)) *
                                                                              10000
                                                                      ) / 100
                                                                    : 0}
                                                                %
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="dash-widget">
                                                        <ProgressBar
                                                            width={20}
                                                            radius={100}
                                                            progress={
                                                                this.state
                                                                    .inconclusiveOrders
                                                                    ? (this
                                                                          .state
                                                                          .inconclusiveOrders /
                                                                          (this
                                                                              .state
                                                                              .negativeOrders +
                                                                              this
                                                                                  .state
                                                                                  .positiveOrders +
                                                                              this
                                                                                  .state
                                                                                  .inconclusiveOrders)) *
                                                                      100
                                                                    : 0
                                                            }
                                                            rotate={-210}
                                                            strokeWidth={16}
                                                            strokeColor="#1b5a90"
                                                            strokeLinecap="square"
                                                            trackStrokeWidth={8}
                                                            trackStrokeColor="#e6e6e6"
                                                            trackStrokeLinecap="square"
                                                            pointerRadius={0}
                                                            initialAnimation={
                                                                true
                                                            }
                                                            transition="1.5s ease 0.5s"
                                                            trackTransition="0s ease"
                                                        >
                                                            <div className="indicator-volume">
                                                                <img
                                                                    src={Icon03}
                                                                    className="img-fluid sars-results-icon"
                                                                    alt="Patient"
                                                                />
                                                            </div>
                                                        </ProgressBar>
                                                        <div className="dash-widget-info">
                                                            <h6>
                                                                Inconclusive /
                                                                Invalid
                                                            </h6>
                                                            <span
                                                                className="text-muted"
                                                                style={{
                                                                    fontSize:
                                                                        "14px"
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .inconclusiveOrders
                                                                }
                                                            </span>
                                                            <h4>
                                                                {this.state
                                                                    .inconclusiveOrders
                                                                    ? Math.round(
                                                                          (this
                                                                              .state
                                                                              .inconclusiveOrders /
                                                                              (this
                                                                                  .state
                                                                                  .negativeOrders +
                                                                                  this
                                                                                      .state
                                                                                      .positiveOrders +
                                                                                  this
                                                                                      .state
                                                                                      .inconclusiveOrders)) *
                                                                              10000
                                                                      ) / 100
                                                                    : 0}
                                                                %
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card dash-card">
                                        <div
                                            className="card-body"
                                            style={{ overflowX: "auto" }}
                                        >
                                            <h3 className="col-12 text-dash mb-3">
                                                Daily Sample Automation Status{" "}
                                                <abbr
                                                    style={{ fontSize: "20px" }}
                                                >
                                                    (
                                                    {moment().format(
                                                        "MM/DD/YYYY"
                                                    )}
                                                    )
                                                </abbr>
                                            </h3>
                                            <MenuList>
                                                <MenuItem
                                                    component={Link}
                                                    to="/clinic/requisitions/not_received"
                                                    target="_blank"
                                                >
                                                    <ListItemIcon>
                                                        <div
                                                            className="icon-box-dash"
                                                            style={{
                                                                backgroundColor:
                                                                    "#00cfe81f"
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-exclamation"
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "#00cfe8"
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Not Received Collection
                                                        Date
                                                        <br />
                                                        {moment()
                                                            .subtract(1, "days")
                                                            .format(
                                                                "MM/DD/YYYY"
                                                            )}
                                                    </ListItemText>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "baseline"
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            className="text-danger mb-0"
                                                        >
                                                            <strong>
                                                                {
                                                                    this.state
                                                                        .samplesNotReceieved
                                                                }
                                                            </strong>
                                                        </Typography>
                                                    </div>
                                                </MenuItem>
                                                <MenuItem
                                                    component={Link}
                                                    to="/clinic/requisitions/cancelled"
                                                    target="_blank"
                                                >
                                                    <ListItemIcon>
                                                        <div
                                                            className="icon-box-dash"
                                                            style={{
                                                                backgroundColor:
                                                                    "#28c76f1f"
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-ban"
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "#28c76f"
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Cancelled Collection
                                                        Date
                                                        <br />
                                                        {moment()
                                                            .subtract(2, "days")
                                                            .format(
                                                                "MM/DD/YYYY"
                                                            )}
                                                    </ListItemText>
                                                    <Typography
                                                        variant="body2"
                                                        className="text-success mb-0"
                                                    >
                                                        <strong>
                                                            {
                                                                this.state
                                                                    .samplesCancelled
                                                            }
                                                        </strong>
                                                    </Typography>
                                                </MenuItem>
                                            </MenuList>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="card dash-card pb-0">
                                        <div className="card-body">
                                            <div className="row">
                                                <div
                                                    className="col-md-12"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <Chart
                                                        options={
                                                            this.state
                                                                .hl7MrnOptions
                                                        }
                                                        series={
                                                            this.state
                                                                .hl7Orders ||
                                                            this.state.bluelotOrders
                                                                ? [
                                                                      this.state
                                                                          .hl7Orders,
                                                                      this.state
                                                                          .bluelotOrders
                                                                  ]
                                                                : [0, 100]
                                                        }
                                                        type="pie"
                                                        width="380"
                                                    />
                                                </div>
                                            </div>
                                            <div className="border-top row hl7-row">
                                                <div className="col-md-12 col-lg-6">
                                                    <div className="dash-widget border-right">
                                                        <div className="dash-widget-info">
                                                            <h6 className="text-dash">
                                                                HL7 Orders
                                                            </h6>
                                                            <h3>
                                                                {
                                                                    this.state
                                                                        .hl7Orders
                                                                }
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-6">
                                                    <div className="dash-widget">
                                                        <div className="dash-widget-info">
                                                            <h6 className="text-dash">
                                                                BlueLot Created
                                                                Orders
                                                            </h6>
                                                            <h3>
                                                                {
                                                                    this.state
                                                                        .bluelotOrders
                                                                }
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="card dash-card">
                                        <div className="card-body">
                                            <h3 className="col-12 text-dash mb-3">
                                                One Time Links
                                            </h3>
                                            <MenuList>
                                                <MenuItem>
                                                    <ListItemIcon>
                                                        <div
                                                            className="icon-box-dash"
                                                            style={{
                                                                backgroundColor:
                                                                    "#00cfe81f"
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-link"
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "#00cfe8"
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Created
                                                    </ListItemText>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "baseline"
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            className="text-danger mb-0"
                                                        >
                                                            <strong>
                                                                {
                                                                    this.state
                                                                        .resultLinksAll
                                                                }
                                                            </strong>
                                                        </Typography>
                                                    </div>
                                                </MenuItem>
                                                <MenuItem>
                                                    <ListItemIcon>
                                                        <div
                                                            className="icon-box-dash"
                                                            style={{
                                                                backgroundColor:
                                                                    "#28c76f1f"
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-user-plus"
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "#28c76f"
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Used
                                                    </ListItemText>
                                                    <Typography
                                                        variant="body2"
                                                        className="text-success mb-0"
                                                    >
                                                        <strong>
                                                            {
                                                                this.state
                                                                    .resultLinksUsed
                                                            }
                                                        </strong>
                                                    </Typography>
                                                </MenuItem>
                                            </MenuList>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card dash-card">
                                        <div className="card-body">
                                            <h3 className="col-12 text-dash mb-3">
                                                Invoices
                                            </h3>
                                            <MenuList>
                                                <MenuItem>
                                                    <ListItemIcon>
                                                        <div
                                                            className="icon-box-dash"
                                                            style={{
                                                                backgroundColor:
                                                                    "#ff9f431f"
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-file-text"
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "#ff9f43"
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Outstanding invoices
                                                    </ListItemText>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "baseline"
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            className="text-danger mb-0"
                                                        >
                                                            <strong>
                                                                {
                                                                    this.state
                                                                        .invoicesOutstanding
                                                                }
                                                            </strong>
                                                        </Typography>
                                                    </div>
                                                </MenuItem>
                                                <MenuItem>
                                                    <ListItemIcon>
                                                        <div
                                                            className="icon-box-dash"
                                                            style={{
                                                                backgroundColor:
                                                                    "#7367f01f"
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-usd"
                                                                style={{
                                                                    color: "#7367f0"
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Paid invoices
                                                    </ListItemText>
                                                    <Typography
                                                        variant="body2"
                                                        className="text-success mb-0"
                                                    >
                                                        <strong>
                                                            {
                                                                this.state
                                                                    .invoicesPaid
                                                            }
                                                        </strong>
                                                    </Typography>
                                                </MenuItem>
                                            </MenuList>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-7">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card dash-card">
                                        <div className="card-body">
                                            <h3 className="col-12 text-dash mb-3">
                                                Top Ten Facilities
                                            </h3>
                                            <TableContainer
                                                component={Paper}
                                                className="top-ten-table"
                                            >
                                                <Table
                                                    sx={{ minWidth: 650 }}
                                                    aria-label="simple table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="text-dash">
                                                                Name
                                                            </TableCell>
                                                            <TableCell
                                                                className="text-dash"
                                                                align="right"
                                                            >
                                                                Completed Orders
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {topFacilities.map(
                                                            (row) => (
                                                                <TableRow
                                                                    key={
                                                                        row.name
                                                                    }
                                                                    sx={{
                                                                        "&:last-child td, &:last-child th":
                                                                            {
                                                                                border: 0
                                                                            }
                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        className="pl-0"
                                                                    >
                                                                        <MenuItem className="dash-table-row">
                                                                            <ListItemIcon className="ml-0">
                                                                                {row.logo ? (
                                                                                    <div className="icon-box-dash">
                                                                                        <img
                                                                                            src={
                                                                                                row.logo
                                                                                            }
                                                                                            alt="facility_logo"
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="icon-box-dash"
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                "#00cfe81f"
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                color: "#00cfe8",
                                                                                                marginRight:
                                                                                                    "0"
                                                                                            }}
                                                                                        >
                                                                                            {row.name[0].toUpperCase()}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </ListItemIcon>
                                                                            <ListItemText>
                                                                                {
                                                                                    row.name
                                                                                }
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <Typography
                                                                            variant="body2"
                                                                            className="text-success mb-0"
                                                                        >
                                                                            <strong>
                                                                                {row.orders
                                                                                    ? row.orders
                                                                                    : 0}
                                                                            </strong>
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClinicDashboard;
