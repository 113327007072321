import React, { Component } from "react";
import { Link } from "react-router-dom";

class AuditBreadcrumb extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="breadcrumb-bar">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-8 col-12">
							<nav aria-label="breadcrumb" className="page-breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/">Home</Link>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Audit & Reports
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Audit
									</li>
								</ol>
							</nav>
							<h2 className="breadcrumb-title">Audit</h2>
						</div>
						<div className="col-md-4 col-12">
							<div className="row breadcrumb-btns">
								<label className="breadcrumb-text"></label>
								<ul className="export-ul">
									<li onClick={() => this.props.onBtnExport()}>Export Grid</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AuditBreadcrumb;
