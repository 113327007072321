import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import PatientBtnCellRenderer from "./patientBtnCellRenderer";
import SampleBtnCellRenderer from "./sampleBtnCellRenderer";
import FacilityBtnCellRenderer from "./facilityBtnCellRenderer";
import PhysicianBtnCellRenderer from "./physicianBtnCellRenderer";
import NotificationLogCellRenderer from "./notificationLogCellRenderer";
import SampleTrackingCellRenderer from "./sampleTrackingCellRenderer";
import RequisitionPdfRenderer from "../../requisitions/clinicRequisitionGrid/requisitionPdfRenderer";
import PdfResultRenderer from "./pdfResultRenderer";
import AnalyteResultRenderer from "./analyteResultRenderer";
import SymptomRenderer from "./symptomRenderer";
import AllGridViews from "./allGridViews";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import { getPlateList } from "../../../../services/limsPortalServices/plateService";
import { getAllTests } from "../../../../services/clinicPortalServices/testService";
import Swal from "sweetalert2";
import _ from "lodash";
//service calls
import { searchOrdersOrder } from "../../../../services/clinicPortalServices/orderSearchService";
import { bulkDownloadZipForPDF } from "../../../../services/clinicPortalServices/orderEditService";
import {
    getViewSettings,
    saveOrderSettings,
    deleteGridViewService,
    renameGridViewService,
    updateGridViewService
} from "../../../../services/clinicPortalServices/userViewSettings";
import {
    serviceConstants,
    auditEnums
} from "../../../../services/common/constants";
import {
    getUserRole,
    processCellForClipboard,
    defaultExcelExportParams,
    getUserDetails,
    isAllowedReducedGridView
} from "../../../../services/common/util";
import OrderSearchMenu from "./orderSearchMenu";
import { settingConstants } from "../../../../services/common/optionsData";
import toastr from "toastr";
import { Checkbox, MenuItem } from "@mui/material";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import NoticeHistoryCellRenderer from "./noticeHistoryCellRenderer";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

export const OrderGridContext = React.createContext();

class ClinicOrderGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDateSave: false,
            isArchived: false,
            isReducedGridView: true,
            facilityIds: [],
            companies: [],
            showAllGridViews: false,
            isCreateDefault: true,
            isRenameView: false,
            isUpdateViews: false,
            showNameModal: false,
            newViewName: "",
            userId: window.localStorage.getItem("USER_ID"),
            viewName: {},
            userSetting: {
                user_id: window.localStorage.getItem("USER_ID"),
                type: settingConstants.GRID,
                page: settingConstants.ORDER,
                grid_views: []
            },
            activeGridView: {
                _id: 0,
                name: "",
                search_filters: {
                    facility_id: [],
                    from_date:
                        moment().subtract(3, "days").format("YYYY-MM-DD") +
                        "T00:00",
                    to_date:
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00",
                    dateRange: "custom",
                    resultValues: [],
                    filter_by: "received_date"
                },
                page_size: settingConstants.DEFAULT_PAGESIZE,
                columns: [],
                date_save: false
            },
            selectedViewId: null,
            gridViews: [],
            defaultGridView: {
                name: settingConstants.DEFAULT_VIEWNAME,
                search_filters: {
                    facility_id: [],
                    from_date:
                        moment().subtract(3, "days").format("YYYY-MM-DD") +
                        "T00:00",
                    to_date:
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00",
                    dateRange: "custom",
                    resultValues: [],
                    filter_by: "received_date"
                },
                page_size: settingConstants.DEFAULT_PAGESIZE,
                columns: [],
                date_save: false
            },
            columnDefs: [
                {
                    headerName: "Order Actions",
                    children: [
                        {
                            headerName: "Order Actions",
                            minWidth: 180,
                            resizable: true,
                            cellStyle: { textAlign: "center" },
                            cellClass: ["order-actions"],
                            cellRenderer: "editBtnCellRenderer"
                        }
                    ]
                },
                {
                    headerName: "Order Basic Info",
                    children: [
                        {
                            headerName: "Requisition",
                            minWidth: 150,
                            field: "lab_order_id"
                        },
                        {
                            headerName: "Patient Name",
                            minWidth: 200,
                            resizable: true,
                            valueGetter: function addColumns(params) {
                                if (params.data.patient_id) {
                                    return (
                                        params.data.patient_id.first_name +
                                        " " +
                                        params.data.patient_id.last_name
                                    );
                                } else {
                                    return "";
                                }
                            },
                            cellRenderer: "patientBtnCellRenderer"
                        },
                        {
                            headerName: "Test",
                            minWidth: 150,
                            field: "test_id.name"
                        },
                        {
                            headerName: "Specimen Type",
                            minWidth: 150,
                            field: "test_info.specimenType",
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Sample Type",
                            minWidth: 150,
                            field: "test_info.sampleType",
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Sample",
                            minWidth: 150,
                            field: "test_info.sample",
                            cellRenderer: "sampleBtnCellRenderer"
                        },
                        {
                            headerName: "Result",
                            field: "results.value",
                            minWidth: 350,
                            resizable: true,
                            cellRenderer: "pdfResultRenderer"
                        },
                        {
                            headerName: "Analyte Result",
                            field: "results.values",
                            minWidth: 350,
                            resizable: true,
                            cellRenderer: "analyteResultRenderer"
                        },
                        {
                            colId: "collected_date",
                            headerName: "Specimen Collected Date",
                            minWidth: 200,
                            resizable: true,
                            field: "test_info.collected",
                            cellRenderer: function (params) {
                                return params.data.test_info &&
                                    params.data.test_info.collected
                                    ? moment(
                                          params.data.test_info.collected,
                                          "YYYYMMDDHHmmss"
                                      ).format("MM/DD/YYYY hh:mm A")
                                    : "";
                            },
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Facility Source",
                            minWidth: 150,
                            resizable: true,
                            field: "facility_source",
                            cellRenderer: "facilityBtnCellRenderer"
                        },
                        {
                            headerName: "Physician",
                            minWidth: 150,
                            resizable: true,
                            valueGetter: function addColumns(params) {
                                if (params.data.provider) {
                                    return (
                                        params.data.provider.first_name +
                                        " " +
                                        params.data.provider.last_name
                                    );
                                } else {
                                    return "";
                                }
                            },
                            cellRenderer: "physicianBtnCellRenderer"
                        },
                        {
                            colId: "received_date",
                            headerName: "Received Date",
                            minWidth: 200,
                            resizable: true,
                            field: "test_info.received",
                            cellRenderer: function (params) {
                                return params.data.test_info &&
                                    params.data.test_info.received
                                    ? moment(
                                          params.data.test_info.received,
                                          "YYYYMMDDHHmmss"
                                      ).format("MM/DD/YYYY hh:mm A")
                                    : "";
                            },
                            columnGroupShow: "open"
                        },
                        {
                            colId: "released_date",
                            headerName: "Released Date",
                            minWidth: 200,
                            resizable: true,
                            field: "results.released",
                            cellRenderer: function (params) {
                                return params.data.results.released
                                    ? moment(
                                          params.data.results.released,
                                          "YYYYMMDDHHmmss"
                                      ).format("MM/DD/YYYY hh:mm A")
                                    : "";
                            },
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Diagnosis Code",
                            minWidth: 150,
                            resizable: true,
                            field: "diagnosis_code",
                            valueGetter: function addColumns(params) {
                                if (
                                    params.data.diagnosis_code &&
                                    params.data.diagnosis_code.length
                                ) {
                                    return params.data.diagnosis_code.join(
                                        ", "
                                    );
                                } else {
                                    return "";
                                }
                            },
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Notification Log",
                            minWidth: 180,
                            field: "notification_state",
                            cellRenderer: "notificationLogCellRenderer",
                            resizable: true
                        },
                        {
                            headerName: "Sample Tracking",
                            resizable: true,
                            cellRenderer: "sampleTrackingCellRenderer",
                            valueGetter: (params) => {
                                if (
                                    params.data.sample_tracking &&
                                    params.data.sample_tracking.length
                                ) {
                                    let eventName =
                                        params.data.sample_tracking[
                                            params.data.sample_tracking.length -
                                                1
                                        ].eventName;
                                    if (eventName === "IMPORT - FOR RETEST") {
                                        if (
                                            [
                                                "Positive SARS-CoV-2",
                                                "SARS-CoV-2 POSITIVE"
                                            ].includes(
                                                params.data.sample_tracking[
                                                    params.data.sample_tracking
                                                        .length - 1
                                                ].result
                                            )
                                        ) {
                                            eventName += " (Positive)";
                                        } else {
                                            eventName +=
                                                " (Inconclusive / Invalid)";
                                        }
                                    }
                                    return eventName;
                                } else {
                                    return "No Info";
                                }
                            }
                        },
                        {
                            headerName: "Requisition PDF",
                            minWidth: 150,
                            field: "requisition_pdf_path",
                            cellRenderer: "requisitionPdfRenderer",
                            resizable: true,
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Patient Symptoms",
                            resizable: true,
                            minWidth: 150,
                            field: "symptoms",
                            cellStyle: { textAlign: "center" },
                            cellRenderer: "symptomRenderer"
                        },
                        {
                            headerName: "Order Notice History",
                            resizable: true,
                            minWidth: 150,
                            field: "noticeHistory",
                            cellStyle: { textAlign: "center" },
                            cellRenderer: "noticeHistoryCellRenderer",
                            valueGetter: (params) => {
                                if (
                                    params.data.noticeHistory &&
                                    params.data.noticeHistory.length
                                ) {
                                    return (
                                        params.data.noticeHistory.length +
                                        " Log" +
                                        (params.data.noticeHistory.length > 1
                                            ? "s"
                                            : "")
                                    );
                                } else {
                                    return "No History";
                                }
                            }
                        },
                        {
                            headerName: "HL7 Status",
                            resizable: true,
                            field: "is_HL7_requisition",
                            valueGetter: function addColumns(params) {
                                let displayText = "";
                                if (params.data.is_HL7_requisition) {
                                    displayText += "Incoming HL7";
                                }
                                if (params.data.HL7_result_sent_status) {
                                    displayText +=
                                        (displayText ? " -> " : "") +
                                        "Outgoing (" +
                                        params.data.HL7_result_sent_status +
                                        ")";
                                }
                                return displayText;
                            }
                        },
                        {
                            headerName: "Is VIP Order?",
                            resizable: true,
                            field: "is_vip_order",
                            valueGetter: function addColumns(params) {
                                return params.data.is_vip_order ? "YES" : "NO";
                            }
                        }
                    ]
                },
                {
                    headerName: "Insurance Information",
                    children: [
                        {
                            headerName: "YES/NO",
                            resizable: true,
                            minWidth: 100,
                            valueGetter: function addColumns(params) {
                                if (
                                    params.data.patient_insurance_id &&
                                    params.data.patient_insurance_id._id
                                ) {
                                    return "YES";
                                } else {
                                    return "NO";
                                }
                            }
                        },
                        {
                            headerName: "Insurance Provider",
                            resizable: true,
                            minWidth: 150,
                            field: "patient_insurance_id.insurance_provider",
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Insurance Member ID",
                            resizable: true,
                            minWidth: 150,
                            field: "patient_insurance_id.insured_member_id",
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Insurance Group Number",
                            resizable: true,
                            minWidth: 150,
                            field: "patient_insurance_id.insured_group_number",
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Relation to Insured",
                            resizable: true,
                            minWidth: 150,
                            field: "patient_insurance_id.relation_to_insured",
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Insurance Holder Name",
                            resizable: true,
                            minWidth: 150,
                            field: "patient_insurance_id.insured_first_name",
                            valueGetter: function addColumns(params) {
                                if (
                                    params.data.patient_insurance_id &&
                                    params.data.patient_insurance_id
                                        .insured_first_name
                                ) {
                                    return (
                                        params.data.patient_insurance_id
                                            .insured_first_name +
                                        " " +
                                        params.data.patient_insurance_id
                                            .insured_last_name
                                    );
                                }
                            },
                            columnGroupShow: "open"
                        },
                        {
                            headerName: "Social Security Number",
                            resizable: true,
                            minWidth: 150,
                            field: "patient_insurance_id.social_security_number",
                            columnGroupShow: "open"
                        }
                    ]
                }
            ],
            components: {
                editBtnCellRenderer: EditBtnCellRenderer,
                pdfResultRenderer: PdfResultRenderer,
                analyteResultRenderer: AnalyteResultRenderer,
                requisitionPdfRenderer: RequisitionPdfRenderer,
                facilityBtnCellRenderer: FacilityBtnCellRenderer,
                patientBtnCellRenderer: PatientBtnCellRenderer,
                sampleBtnCellRenderer: SampleBtnCellRenderer,
                physicianBtnCellRenderer: PhysicianBtnCellRenderer,
                notificationLogCellRenderer: NotificationLogCellRenderer,
                sampleTrackingCellRenderer: SampleTrackingCellRenderer,
                symptomRenderer: SymptomRenderer,
                noticeHistoryCellRenderer: NoticeHistoryCellRenderer
            },
            paginationNumberFormatter: function (params) {
                return "[" + params.value.toLocaleString() + "]";
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true,
                resizable: true
            },
            rowData: [],
            context: { componentParent: this },
            excelStyles: [
                {
                    id: "header",
                    interior: {
                        color: "#aaaaaa",
                        pattern: "Solid"
                    }
                },
                {
                    id: "body",
                    interior: {
                        color: "#dddddd",
                        pattern: "Solid"
                    }
                }
            ],
            facilities: [],
            plateList: [],
            tests: [],
            testIds: [],
            allResultValues: [],
            updateResultValues: false,
            bundleIds: [],
            allBundleIds: []
        };
        getPlateList().then((res) => {
            if (res && res.data) {
                this.setState({ plateList: res.data });
            }
        });
        getAllTests({ type: "all" }).then((res) => {
            if (res && res.data) {
                // let allResultValues = [];
                // let allBundleIds = [];
                // res.data.map((test) => {
                //     test.results.map((result) => {
                //         allResultValues.push(result);
                //         return null;
                //     });
                //     if (test.is_bundle) {
                //         allBundleIds.push(test._id);
                //     }
                //     return null;
                // });
                // allResultValues.sort();
                this.setState(
                    {
                        tests: res.data,
                        testIds: res.data.map((item) => item._id)
                        // allResultValues: allResultValues,
                        // allBundleIds: allBundleIds,
                        // bundleIds: allBundleIds
                    },
                    () => {
                        this.loadFacilities(this.state.isArchived);
                    }
                );
            }
        });
    }

    loadFacilities = (isArchived, type) => {
        let { defaultGridView, facilities } = this.state;
        let defaultFilters = defaultGridView.search_filters;
        let facilityIds = [];

        let isAllFacilities = false;
        if (
            defaultFilters.facility_id &&
            defaultFilters.facility_id.length > 0 &&
            defaultFilters.facility_id.length === facilities.length &&
            facilities.length > 0
        ) {
            isAllFacilities = true;
        }

        getCompanyWithFacility(isArchived).then((response) => {
            let facilities = response.data;
            if (facilities.length > 0) {
                // facilityIds = [];
                facilities.map((fac) => {
                    facilityIds.push(fac._id);
                    return null;
                });
                defaultFilters.facility_id = facilityIds;
            } else {
                defaultFilters.facility_id = facilityIds;
            }
            defaultFilters.resultValues = this.state.allResultValues;

            if (
                !isAllFacilities &&
                window.localStorage.getItem("FACILITY_ID") &&
                JSON.parse(window.localStorage.getItem("FACILITY_ID")).length
            ) {
                defaultFilters.facility_id = JSON.parse(
                    window.localStorage.getItem("FACILITY_ID")
                );
            }
            defaultGridView.search_filters = defaultFilters;

            this.setState({
                facilities,
                defaultGridView,
                facilityIds,
                updateResultValues: true
            });
            if (type === "archiveAction") {
                let activeGridView = this.state.activeGridView;
                activeGridView.search_filters.facility_id = facilityIds;
                this.setState({ activeGridView: activeGridView }, () => {
                    this.loadGridData(this.state.activeGridView.search_filters);
                });
            } else {
                this.loadUserSetting(defaultGridView);
            }
        });
    };

    initDateRange = (grid_views) => {
        return grid_views.map((view) => {
            if (
                moment(view.search_filters.to_date).format("YYYY-MM-DD") ===
                moment().add(1, "days").format("YYYY-MM-DD")
            ) {
                if (
                    moment(view.search_filters.from_date).format(
                        "YYYY-MM-DD"
                    ) === moment().format("YYYY-MM-DD")
                ) {
                    view.search_filters.dateRange = "today";
                } else if (
                    moment(view.search_filters.from_date).format(
                        "YYYY-MM-DD"
                    ) === moment().subtract(7, "d").format("YYYY-MM-DD")
                ) {
                    view.search_filters.dateRange = "week";
                } else if (
                    moment(view.search_filters.from_date).format(
                        "YYYY-MM-DD"
                    ) === moment().subtract(31, "d").format("YYYY-MM-DD")
                ) {
                    view.search_filters.dateRange = "month";
                } else {
                    view.search_filters.dateRange = "custom";
                }
            } else {
                view.search_filters.dateRange = "custom";
            }
            return view;
        });
    };

    loadUserSetting = (defaultGridView) => {
        let { userId, userSetting } = this.state;
        let viewId = "",
            activeGridView = null;
        let params = {
            user_id: userId,
            type: settingConstants.GRID,
            page: settingConstants.ORDER
        };
        getViewSettings(params).then((response) => {
            if (response.data && response.data.length > 0) {
                userSetting = response.data[0];
                if (userSetting.grid_views.length > 0) {
                    viewId = window.localStorage.getItem("VIEW_ORDER");
                    userSetting.grid_views = this.initDateRange(
                        userSetting.grid_views
                    );
                    let activeGridViewTemp = viewId
                        ? userSetting.grid_views.find((x) => x._id === viewId)
                        : null;
                    activeGridView = activeGridViewTemp
                        ? _.cloneDeep(activeGridViewTemp)
                        : _.cloneDeep(userSetting.grid_views[0]);
                    window.localStorage.setItem(
                        "VIEW_ORDER",
                        activeGridView._id
                    );
                    if (activeGridView.name === "Default") {
                        activeGridView.search_filters = _.cloneDeep(
                            defaultGridView.search_filters
                        );
                    } else if (!activeGridView.date_save) {
                        activeGridView.search_filters.from_date = _.cloneDeep(
                            defaultGridView.search_filters.from_date
                        );
                        activeGridView.search_filters.to_date = _.cloneDeep(
                            defaultGridView.search_filters.to_date
                        );
                        activeGridView.search_filters.facility_id = _.cloneDeep(
                            defaultGridView.search_filters.facility_id
                        );
                    }
                    this.setState({
                        activeGridView: activeGridView,
                        userSetting: userSetting
                    });
                    this.loadGridData(activeGridView.search_filters);
                } else {
                    this.createView(settingConstants.DEFAULT_VIEWNAME);
                }
            } else {
                this.createView(settingConstants.DEFAULT_VIEWNAME);
            }
        });
    };

    checkRealFacilities = (searchFilters) => {
        let realFacilities = [];
        searchFilters.facility_id.map((facilityId) => {
            if (this.state.facilityIds.includes(facilityId)) {
                realFacilities.push(facilityId);
            }
            return null;
        });
        return realFacilities;
    };

    loadGridData = (searchFilterConditions = null) => {
        let searchFilters;
        if (!searchFilterConditions) {
            searchFilters = this.state.activeGridView.search_filters;
        } else {
            searchFilters = searchFilterConditions;
        }
        searchFilters.facility_id = this.checkRealFacilities(searchFilters);
        this.gridApi.showLoadingOverlay();
        searchFilters.testIds = this.state.testIds;
        searchOrdersOrder(searchFilters).then((response) => {
            this.setState({ rowData: response.data });
            if (!response.data.length) {
                this.gridApi.showNoRowsOverlay();
            } else {
                this.gridApi.hideOverlay();
            }
            this.loadGridSchema();
            this.sortByColumn(searchFilters.filter_by, "desc");
        });
    };

    loadGridSchema = () => {
        let { activeGridView } = this.state;
        const columnState = activeGridView.columns;
        if (columnState.length > 0 && activeGridView.name !== "Default") {
            this.gridColumnApi.applyColumnState({
                state: columnState,
                applyOrder: true
            });
        } else {
            this.gridColumnApi.resetColumnState();
            activeGridView.columns = this.gridColumnApi.getColumnState();
            this.setState({ activeGridView });
        }
        this.pageSizeChange(activeGridView.page_size);
    };

    onIsArchivedInformation = (event) => {
        this.setState({ isArchived: event.target.checked });
        this.loadFacilities(event.target.checked, "archiveAction");
    };

    onIsReducedGridView = (event) => {
        this.setState({ isReducedGridView: event.target.checked });
        this.loadGridData(this.state.activeGridView.search_filters);
    };

    handleTabChange = (selectedViewId) => {
        let { activeGridView, defaultGridView, userSetting } = this.state;
        if (
            selectedViewId !== activeGridView._id &&
            !selectedViewId.includes("_btn")
        ) {
            let selectedGridView = userSetting.grid_views.find(
                (view) => view._id === selectedViewId
            );
            window.localStorage.setItem("VIEW_ORDER", selectedViewId);
            if (selectedGridView.name === "Default")
                selectedGridView.search_filters = _.cloneDeep(
                    defaultGridView.search_filters
                );
            else if (!activeGridView.date_save) {
                activeGridView.search_filters.from_date = _.cloneDeep(
                    defaultGridView.search_filters.from_date
                );
                activeGridView.search_filters.to_date = _.cloneDeep(
                    defaultGridView.search_filters.to_date
                );
            }
            this.setState({ activeGridView: _.cloneDeep(selectedGridView) });
            this.loadGridData(selectedGridView.search_filters);
        }
    };

    updateDateRange = (dateRange) => {
        let { activeGridView } = this.state;
        let filters = activeGridView.search_filters;
        if (activeGridView.search_filters.dateRange !== dateRange) {
            switch (dateRange) {
                case "today":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().format("YYYY-MM-DD") + "T00:00";
                    activeGridView.search_filters.dateRange = dateRange;
                    break;
                case "week":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("week").format("YYYY-MM-DD") +
                        "T00:00";
                    activeGridView.search_filters.dateRange = dateRange;
                    break;
                case "month":
                    filters.to_date =
                        moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
                    filters.from_date =
                        moment().startOf("month").format("YYYY-MM-DD") +
                        "T00:00";
                    activeGridView.search_filters.dateRange = dateRange;
                    break;
                default:
                    break;
            }
            this.setState({ activeGridView });
            if (dateRange !== "custom") {
                this.loadGridData(filters);
            }
        }
    };

    setDateRange = (gridView) => {
        if (
            moment(gridView.search_filters.to_date).format("YYYY-MM-DD") ===
            moment().add(1, "days").format("YYYY-MM-DD")
        ) {
            if (
                moment(gridView.search_filters.from_date).format(
                    "YYYY-MM-DD"
                ) === moment().format("YYYY-MM-DD")
            ) {
                gridView.search_filters.dateRange = "today";
            } else if (
                moment(gridView.search_filters.from_date).format(
                    "YYYY-MM-DD"
                ) === moment().startOf("week").format("YYYY-MM-DD")
            ) {
                gridView.search_filters.dateRange = "week";
            } else if (
                moment(gridView.search_filters.from_date).format(
                    "YYYY-MM-DD"
                ) === moment().startOf("month").format("YYYY-MM-DD")
            ) {
                gridView.search_filters.dateRange = "month";
            } else {
                gridView.search_filters.dateRange = "custom";
            }
        } else {
            gridView.search_filters.dateRange = "custom";
        }
    };

    handleDateFiltersChange = (dateTime, type) => {
        let { activeGridView } = this.state;
        const filters = activeGridView.search_filters;
        let filterIsValid = true;
        if (type === "from_date") {
            if (moment(filters.to_date).isAfter(dateTime)) {
                filters.from_date = dateTime;
            } else {
                filterIsValid = false;
            }
        } else {
            if (moment(dateTime).isAfter(filters.from_date)) {
                filters.to_date = dateTime;
            } else {
                filterIsValid = false;
            }
        }
        if (filterIsValid) {
            this.setDateRange(activeGridView);
            this.setState({ activeGridView });
            this.loadGridData(filters);
        }
    };

    handleFiltersChange = (e) => {
        let { activeGridView, facilityIds, facilities } = this.state;
        let filters = activeGridView.search_filters;
        switch (e.target.name) {
            case "filter_by": {
                filters.filter_by = e.target.value;
                break;
            }
            case "testIds": {
                let testIds = this.state.testIds;
                if (e.target.value.includes("all")) {
                    if (testIds.length === this.state.tests.length) {
                        this.setState({ testIds: [] });
                    } else {
                        this.setState({
                            testIds: this.state.tests.map((test) => test._id)
                        });
                    }
                } else {
                    this.setState({
                        testIds: e.target.value
                    });
                }
                break;
            }
            case "facility_id": {
                let facilityIdChanged = [],
                    isAdded = false,
                    facilitiesIn = [],
                    facilityIdsTemp = [];
                let newFacilityIds = e.target.value;
                if (e.target.value.includes("all")) {
                    if (filters.facility_id.length === facilityIds.length) {
                        filters.facility_id = [];
                    } else {
                        filters.facility_id = _.cloneDeep(facilityIds);
                    }
                } else {
                    if (filters.facility_id.length > newFacilityIds.length) {
                        facilityIdChanged = filters.facility_id.find(
                            (val) => !newFacilityIds.includes(val)
                        );
                    } else {
                        facilityIdChanged = newFacilityIds.find(
                            (val) => !filters.facility_id.includes(val)
                        );
                        isAdded = true;
                    }
                    let facilityChanged = facilities.find(
                        (facility) => facility._id === facilityIdChanged
                    );
                    facilityIdsTemp = _.cloneDeep(filters.facility_id);
                    // If company is changed
                    if (facilityChanged.type === "company") {
                        let companyChanged = facilityChanged;
                        facilities.forEach((fac) => {
                            if (fac.parent_id === companyChanged._id) {
                                facilitiesIn.push(fac._id);
                            }
                        });
                        let index = facilityIdsTemp.indexOf(companyChanged._id);
                        if (facilitiesIn.length > 0) {
                            // If company is selected
                            if (isAdded) {
                                // Add company Id
                                facilityIdsTemp.push(companyChanged._id);
                                // Add facility Ids
                                facilitiesIn.forEach((facId) => {
                                    if (!facilityIdsTemp.includes(facId)) {
                                        facilityIdsTemp.push(facId);
                                    }
                                });
                            } else {
                                // If company is unselected
                                // Remove company Id
                                if (index > -1) {
                                    facilityIdsTemp.splice(index, 1);
                                }
                                facilitiesIn.forEach((facId) => {
                                    let index = facilityIdsTemp.indexOf(facId);
                                    if (index > -1) {
                                        facilityIdsTemp.splice(index, 1);
                                    }
                                });
                            }
                        } else {
                            isAdded
                                ? facilityIdsTemp.push(companyChanged._id)
                                : facilityIdsTemp.splice(index, 1);
                        }
                    } else {
                        // If facility is changed
                        let companyId = facilityChanged.parent_id;
                        let facilitiesIn = [];
                        facilities.forEach((fac) => {
                            if (fac.parent_id === companyId) {
                                facilitiesIn.push(fac._id);
                            }
                        });
                        // If facility Id is selected
                        if (isAdded) {
                            // Add facility Id
                            facilityIdsTemp.push(facilityChanged._id);
                            if (!filters.facility_id.includes(companyId)) {
                                facilityIdsTemp.push(companyId);
                            }
                        } else {
                            // If facility Id is unselected
                            // Remove facility Id
                            let index = facilityIdsTemp.indexOf(
                                facilityChanged._id
                            );
                            if (index > -1) {
                                facilityIdsTemp.splice(index, 1);
                            }
                            // Remove company Id
                            let facilitiesInFilters = [];
                            facilitiesIn.forEach((fac) => {
                                if (facilityIdsTemp.includes(fac)) {
                                    facilitiesInFilters.push(fac);
                                }
                            });
                            if (facilitiesInFilters.length === 0) {
                                index = facilityIdsTemp.indexOf(companyId);
                                if (index > -1) {
                                    facilityIdsTemp.splice(index, 1);
                                }
                            }
                        }
                    }
                    filters.facility_id = _.cloneDeep(facilityIdsTemp);
                    window.localStorage.setItem(
                        "FACILITY_ID",
                        JSON.stringify(filters.facility_id)
                    );
                }
                break;
            }
            default:
                break;
        }

        this.setState({ activeGridView }, () => {
            this.loadGridData(filters);
        });
    };

    onGridReady = (params) => {
        let { defaultGridView } = this.state;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        defaultGridView.columns = this.gridColumnApi.getColumnState();
        this.setState({ defaultGridView });
    };

    sortByColumn = (type, sortType) => {
        this.gridColumnApi.applyColumnState({
            state: [
                {
                    colId: type,
                    sort: sortType
                }
            ],
            defaultState: { sort: null }
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({});
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.OrderGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.OrderGridExported
        };
        createAudit(auditData);
    };

    onBtnExportZipForResults = () => {
        let pdfArray = [];
        this.gridApi.forEachNodeAfterFilter((node) => {
            if (node.data.results && node.data.results.pdf_path) {
                pdfArray.push(node.data.results.pdf_path);
            }
        });
        if (pdfArray.length) {
            if (pdfArray.length > 1000) {
                toastr.warning(
                    "Maximum limit of 1000 reached.  Please reduce date range."
                );
            } else {
                const params = {
                    pdfArray: pdfArray,
                    userId: window.localStorage.getItem("USER_ID"),
                    type: "resultPDF"
                };
                boundActions.startBlockUILoading();
                bulkDownloadZipForPDF(params)
                    .then((response) => {
                        boundActions.endBlockUILoading();
                        if (
                            response.data &&
                            response.data.RESULT === "SUCCESS"
                        ) {
                            window.open(
                                serviceConstants.HOST_NAME + response.data.path
                            );
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } else {
            toastr.warning("There is no filtered result");
        }
    };

    onBtnExportZipForRequisitions = () => {
        let pdfArray = [];
        this.gridApi.forEachNodeAfterFilter((node) => {
            if (node.data.requisition_pdf_path) {
                pdfArray.push(
                    "/files/requisitionPDF/" + node.data.requisition_pdf_path
                );
            }
        });
        if (pdfArray.length) {
            if (pdfArray.length > 1000) {
                toastr.warning(
                    "Maximum limit of 1000 reached.  Please reduce date range."
                );
            } else {
                const params = {
                    pdfArray: pdfArray,
                    userId: window.localStorage.getItem("USER_ID"),
                    type: "requisitionPDF"
                };
                boundActions.startBlockUILoading();
                bulkDownloadZipForPDF(params)
                    .then((response) => {
                        boundActions.endBlockUILoading();
                        if (
                            response.data &&
                            response.data.RESULT === "SUCCESS"
                        ) {
                            window.open(
                                serviceConstants.HOST_NAME + response.data.path
                            );
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } else {
            toastr.warning("There is no item has requisition pdf file");
        }
    };

    onPageSizeChanged = (e) => {
        this.pageSizeChange(e.target.value);
    };

    pageSizeChange = (pageSize) => {
        let { activeGridView } = this.state;
        activeGridView.page_size = pageSize;
        this.setState({ activeGridView });
        this.gridApi.paginationSetPageSize(Number(pageSize));
    };

    resetState = () => {
        this.pageSizeChange(settingConstants.DEFAULT_PAGESIZE);
        this.clearFilter();
    };

    clearFilter = () => {
        let { activeGridView, defaultGridView } = this.state;
        let viewInfoPrev = _.cloneDeep(activeGridView);
        defaultGridView.search_filters.facility_id = this.state.facilityIds;
        defaultGridView.search_filters.resultValues =
            this.state.allResultValues;
        activeGridView = _.cloneDeep(defaultGridView);
        if (viewInfoPrev._id) {
            activeGridView._id = viewInfoPrev._id;
        }
        activeGridView.name = viewInfoPrev.name;
        window.localStorage.removeItem("FACILITY_ID");
        window.localStorage.removeItem("FROM_DATE_RANGE");
        window.localStorage.removeItem("TO_DATE_RANGE");
        this.setState({
            activeGridView,
            updateResultValues: true,
            bundleIds: this.state.allBundleIds
        });
        this.loadGridData(activeGridView.search_filters);
    };

    openCreateView = (isCreateDefault) => {
        this.setState({
            showNameModal: true,
            isCreateDefault,
            isRenameView: false
        });
    };

    saveView = () => {
        let { isRenameView, newViewName } = this.state;
        if (isRenameView) {
            this.renameView(newViewName);
        } else {
            this.createView(newViewName);
        }
    };

    updateView = () => {
        let { activeGridView, userSetting } = this.state;
        activeGridView.columns = this.gridColumnApi.getColumnState();
        activeGridView.search_filters.facility_id = _.cloneDeep(
            this.state.defaultGridView.search_filters.facility_id
        );
        let viewInfo = {
            _id: userSetting._id,
            grid_view: activeGridView
        };
        Swal.fire({
            title: "Save Current Dates?",
            text: "Do you want to save the current dates?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            },
            allowOutsideClick: false
        }).then((result) => {
            activeGridView.date_save = result.isConfirmed ? true : false;
            updateGridViewService(viewInfo).then((response) => {
                if (response.status === 200) {
                    userSetting = response.data;
                    userSetting.grid_views = this.initDateRange(
                        userSetting.grid_views
                    );
                    this.setState({ userSetting });
                    toastr.success("View saved successfully.");
                    this.handleClose();
                } else {
                    toastr.error("Error");
                }
            });
        });
    };

    createView = (newName) => {
        let newGridView = {};
        let {
            activeGridView,
            defaultGridView,
            userSetting,
            isCreateDefault,
            isDateSave
        } = this.state;
        let isNameExists = userSetting.grid_views.some(
            (view) => view.name === newName
        );
        if (!isNameExists) {
            if (isCreateDefault) {
                newGridView = _.cloneDeep(defaultGridView);
            } else {
                newGridView = _.cloneDeep(activeGridView);
                delete newGridView._id;
            }
            newGridView.name = newName;
            newGridView.date_save = isDateSave;
            if (isDateSave) {
                newGridView.search_filters.from_date = _.cloneDeep(
                    activeGridView.search_filters.from_date
                );
                newGridView.search_filters.to_date = _.cloneDeep(
                    activeGridView.search_filters.to_date
                );
            } else {
                newGridView.search_filters.from_date = _.cloneDeep(
                    defaultGridView.search_filters.from_date
                );
                newGridView.search_filters.to_date = _.cloneDeep(
                    defaultGridView.search_filters.to_date
                );
            }
            newGridView.search_filters.facility_id = _.cloneDeep(
                defaultGridView.search_filters.facility_id
            );
            userSetting.grid_views.push(newGridView);
            this.saveState(userSetting);
        } else {
            toastr.warning("Same name exists.");
        }
    };

    updateViews = (gridViews) => {
        let { userSetting } = this.state;
        userSetting.grid_views = gridViews;
        this.setState({ isUpdateViews: true });
        this.saveState(userSetting);
    };
    saveState = (gridSetting) => {
        saveOrderSettings(gridSetting).then((response) => {
            if (response.status === 200) {
                let { isUpdateViews, activeGridView } = this.state;
                let userSetting = response.data;
                userSetting.grid_views = this.initDateRange(
                    userSetting.grid_views
                );
                if (!isUpdateViews) {
                    let lastGridViewIndex = userSetting.grid_views.length - 1;
                    activeGridView = _.cloneDeep(
                        userSetting.grid_views[lastGridViewIndex]
                    );
                    window.localStorage.setItem(
                        "VIEW_ORDER",
                        activeGridView._id
                    );
                    this.setState({
                        activeGridView,
                        userSetting,
                        isCreateDefault: true
                    });
                    this.loadGridData(activeGridView.search_filters);
                    if (activeGridView.name !== "Default")
                        toastr.success("Settings saved successfully.");
                } else {
                    this.setState({
                        activeGridView,
                        userSetting,
                        isUpdateViews: false
                    });
                }

                this.handleClose();
            } else if (response.status === 403) {
                toastr.error(response.message);
            } else {
                toastr.error("Error");
            }
        });
    };

    handleClose = () => {
        this.setState({
            showNameModal: false,
            newViewName: "",
            isDateSave: false
        });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    renameActiveView = () => {
        let { activeGridView } = this.state;
        this.setState({ newViewName: activeGridView.name });
        this.openRenameView(activeGridView._id);
    };

    openRenameView = (selectedViewId) => {
        let { userSetting } = this.state;
        let selectedView = userSetting.grid_views.find(
            (view) => view._id === selectedViewId
        );
        this.setState({
            isRenameView: true,
            showNameModal: true,
            selectedViewId,
            newViewName: selectedView.name
        });
    };

    toggleItem = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    renameView = () => {
        let { selectedViewId, userSetting, newViewName } = this.state;
        let isNameExists = userSetting.grid_views.some(
            (view) => view.name === newViewName && view._id !== selectedViewId
        );
        if (isNameExists) {
            toastr.warning("Same name exists.");
        } else {
            let viewInfo = {
                _id: userSetting._id,
                view_id: selectedViewId,
                view_name: newViewName
            };
            renameGridViewService(viewInfo).then((response) => {
                if (response.status === 200) {
                    userSetting = response.data;
                    userSetting.grid_views = this.initDateRange(
                        userSetting.grid_views
                    );
                    let activeGridView = _.cloneDeep(
                        userSetting.grid_views.find(
                            (view) => view._id === selectedViewId
                        )
                    );
                    this.setState({
                        userSetting,
                        activeGridView,
                        isRenameView: false
                    });
                    toastr.success("View renamed successfully.");
                    this.handleClose();
                } else {
                    toastr.error("Error");
                }
            });
        }
    };

    deleteActiveView = () => {
        let { activeGridView } = this.state;
        this.openDelteView(activeGridView._id);
    };
    openDelteView = (viewId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this view!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteView(viewId);
            }
        });
    };

    deleteView = (viewId) => {
        let { userSetting } = this.state;
        const viewInfo = {
            _id: userSetting._id,
            view_id: viewId
        };
        deleteGridViewService(viewInfo).then((response) => {
            if (response.status === 200) {
                userSetting = response.data;
                let newActiveGridView = userSetting.grid_views[0];
                this.setState({ userSetting });
                this.handleTabChange(newActiveGridView._id);
                toastr.success("View deleted successfully.");
                this.handleClose();
            } else {
                toastr.error("Error");
            }
        });
    };

    handleKeyDown = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.saveView();
        }
    };

    openAllGridViews = (isOpen) => {
        this.setState({ showAllGridViews: isOpen });
    };

    updateBundleIds = (bundleId) => {
        let bundleIds = this.state.bundleIds;
        if (bundleIds.includes(bundleId)) {
            bundleIds = bundleIds.filter((item) => item !== bundleId);
        } else {
            bundleIds.push(bundleId);
        }
        this.setState({ bundleIds: bundleIds }, () => {
            this.loadGridData();
        });
    };

    render() {
        const {
            showAllGridViews,
            newViewName,
            activeGridView,
            isCreateDefault,
            isRenameView,
            userSetting,
            facilities,
            showNameModal,
            isReducedGridView
        } = this.state;
        let columnDefs = [];

        switch (getUserRole().toLowerCase()) {
            case "salesperson":
            case "salesadmin":
                columnDefs = this.state.columnDefs[1].children.filter((x) =>
                    /Patient Name|Facility Source|Physician|Released Date/.test(
                        x.headerName
                    )
                );
                break;
            case "labtech":
                columnDefs = this.state.columnDefs.filter(
                    (x) => x.headerName !== "Order Actions"
                );
                break;
            case "audit":
                columnDefs = this.state.columnDefs.filter(
                    (x) => x.headerName !== "Order Actions"
                );
                break;
            default:
                columnDefs = this.state.columnDefs;
                break;
        }

        if (isReducedGridView && isAllowedReducedGridView("order")) {
            columnDefs = [
                columnDefs[0].children[0],
                ...columnDefs[1]?.children.filter(
                    (x) =>
                        /Requisition|Patient Name|Test|Specimen|Sample|Result|Facility Source|Physician|Received Date|Released Date/.test(
                            x.headerName
                        ) &&
                        !/Sample Tracking|Requisition PDF/.test(x.headerName)
                )
            ];
            columnDefs[columnDefs.length - 1]["resizable"] = false;
        }

        return (
            <div className="clinic-contain">
                <OrderGridContext.Provider
                    value={{
                        isReducedGridView,
                        onIsReducedGridView: this.onIsReducedGridView
                    }}
                >
                    <OrderSearchMenu
                        onIsArchivedInformation={(event) => {
                            this.onIsArchivedInformation(event);
                        }}
                        isArchived={this.state.isArchived}
                        facilities={facilities}
                        gridViews={userSetting.grid_views}
                        activeGridView={activeGridView}
                        facilityId={activeGridView.search_filters.facility_id}
                        from_date={activeGridView.search_filters.from_date}
                        to_date={activeGridView.search_filters.to_date}
                        resultValues={
                            activeGridView.search_filters.resultValues
                        }
                        bundleIds={this.state.bundleIds}
                        updateBundleIds={this.updateBundleIds}
                        filter_by={activeGridView.search_filters.filter_by}
                        page_size={activeGridView.page_size}
                        date_range={activeGridView.search_filters.dateRange}
                        updateDateRange={this.updateDateRange}
                        openCreateView={this.openCreateView}
                        onFilterTextChange={this.onFilterTextChange}
                        handleTabChange={this.handleTabChange}
                        handleFiltersChange={this.handleFiltersChange}
                        handleDateFiltersChange={this.handleDateFiltersChange}
                        clearFilter={this.clearFilter}
                        onPageSizeChanged={this.onPageSizeChanged}
                        updateView={this.updateView}
                        saveState={this.saveState}
                        resetState={this.resetState}
                        onBtnExport={this.onBtnExport}
                        onBtnExportZipForResults={this.onBtnExportZipForResults}
                        onBtnExportZipForRequisitions={
                            this.onBtnExportZipForRequisitions
                        }
                        openRenameView={this.openRenameView}
                        deleteActiveView={this.deleteActiveView}
                        openAllGridViews={this.openAllGridViews}
                        renameActiveView={this.renameActiveView}
                        tests={this.state.tests}
                        testIds={this.state.testIds}
                        context={this.state.context}
                        updateResultValues={this.state.updateResultValues}
                    />
                </OrderGridContext.Provider>
                <div
                    style={{
                        width: "100%",
                        height: "100vh",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    components={this.state.components}
                                    pagination={true}
                                    paginationPageSize={20}
                                    processCellForClipboard={
                                        processCellForClipboard
                                    }
                                    paginationNumberFormatter={
                                        this.state.paginationNumberFormatter
                                    }
                                    excelStyles={this.state.excelStyles}
                                    context={this.state.context}
                                    defaultExcelExportParams={
                                        defaultExcelExportParams
                                    }
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>

                {/* Create modal */}
                <ModalStyled
                    show={showNameModal}
                    onHide={this.handleClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {isRenameView
                                ? "Update Name"
                                : "Create a new " +
                                  (isCreateDefault ? "" : " saved") +
                                  " view"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <label>
                                    Name <span className="text-danger"> *</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="newViewName"
                                    value={newViewName}
                                    onChange={this.handleChange}
                                    onKeyDown={this.handleKeyDown}
                                />
                            </div>
                            {!this.state.isRenameView && (
                                <div className="col-12 mt-3">
                                    <MenuItem
                                        onClick={() =>
                                            this.toggleItem("isDateSave")
                                        }
                                        style={{ paddingLeft: "0px" }}
                                    >
                                        <Checkbox
                                            checked={this.state.isDateSave}
                                        />{" "}
                                        Save Current Dates
                                    </MenuItem>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={this.saveView}
                            disabled={!this.state.newViewName}
                        >
                            Save
                        </Button>
                    </Modal.Footer>
                </ModalStyled>

                <ModalStyled
                    show={showAllGridViews}
                    onHide={() => this.openAllGridViews(false)}
                    className="modal-container custom-map-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>All saved views</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AllGridViews
                            gridViews={userSetting.grid_views}
                            openRenameView={this.openRenameView}
                            openDelteView={this.openDelteView}
                            updategridViews={this.updategridViews}
                            updateViews={this.updateViews}
                        />
                    </Modal.Body>
                </ModalStyled>
            </div>
        );
    }
}

export default ClinicOrderGrid;
