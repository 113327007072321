import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
//service calls
import { searchVipPayments } from "../../../../services/clinicPortalServices/vipPaymentsServices";
import VipPaymentSerachMenu from "./vipPaymentSearchMenu";
import moment from 'moment';
import { getUserRole, dateComparator } from "../../../../services/common/util";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicVipPaymentGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchFilters: {
				from_date: moment().subtract(1, "days").format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
			},
			columnDefs: [
				{
					headerName: "Payment Info",
					children: [
						{
							headerName: "VIP Payment ID",
							field: "_id",
							minWidth: 110,
							resizable: true
						},
						{
							headerName: "Name",
							field: "first_name",
							minWidth: 110,
							resizable: true,
							valueGetter: function (params) {
								return params.data.first_name + ' ' + params.data.last_name;
							}
						},
						{
							headerName: "Is Paid?",
							field: "is_paid",
							minWidth: 110,
							resizable: true,
							valueGetter: function (params) {
								return params.data.is_paid ? "YES" : "NO";
							}
						},
						{
							headerName: "Date Of Birth",
							field: "first_name",
							minWidth: 110,
							resizable: true,
							valueGetter: function (params) {
								return moment(params.data.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY");
							},
							comparator: dateComparator,
						},
						{
							headerName: "Email",
							field: "email",
							minWidth: 110,
							resizable: true
						},
						{
							headerName: "Mobile",
							field: "mobile",
							minWidth: 110,
							resizable: true
						},
						{
							headerName: "Chosen Time",
							field: "mobile",
							minWidth: 110,
							resizable: true,
							valueGetter: function (params) {
								return moment(params.data.date, "YYYYMMDD").format("MM/DD/YYYY") + ' ' + params.data.ampm.toUpperCase();
							},
						},
						{
							headerName: "Provided Sample ID",
							field: "provided_sample",
							resizable: true,
						},
					]
				},
				{
					headerName: "Order Info",
					children: [
						{
							headerName: "Order Sample ID",
							field: "order_sample",
							resizable: true,
						},
						{
							headerName: "Facility Name",
							field: "order_id.facility_source",
							resizable: true,
						},
						{
							headerName: "Physician Name",
							resizable: true,
							valueGetter: function (params) {
								if (params.data.order_id && params.data.order_id.provider) {
									return params.data.order_id.provider.first_name + " " + params.data.order_id.provider.last_name;
								}
							}
						}
					]
				}
			],

			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			drivers: [],
			context: { componentParent: this }
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		const params = {
			"date": {
				$gte: moment(this.state.searchFilters.from_date).format("YYYYMMDD"),
				$lte: moment(this.state.searchFilters.to_date).format("YYYYMMDD"),
			},
		}
		searchVipPayments(params)
			.then((response) => {
				if (response.data && response.data.length) {
					this.setState({ rowData: response.data });
				} else {
					this.setState({ rowData: [] });
				}
			})
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		this.setState({
			searchFilters: {
				from_date: moment().subtract(1, "days").format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
			},
		})
		document.getElementById("reset-form").value = "";
	};

	handleDateFiltersChange = (dateTime, type) => {
		const filters = this.state.searchFilters;
		let filterIsValid = true;
		if (type === 'from_date') {
			if (moment(filters.to_date).isAfter(dateTime)) {
				filters.from_date = dateTime;
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(filters.from_date)) {
				filters.to_date = dateTime;
			} else {
				filterIsValid = false;
			}
		}
		if (filterIsValid) {
			this.setState({ searchFilters: filters });
			this.loadGridData();
		}
	}

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
	};

	render() {
		let columnDefs = /salesperson|audit|salesadmin/.test(getUserRole().toLowerCase()) ? this.state.columnDefs.filter(x => x.headerName !== 'Actions') : this.state.columnDefs;
		return (
			<div className="clinic-contain">
				<VipPaymentSerachMenu
					handleDateFiltersChange={this.handleDateFiltersChange}
					from_date={this.state.searchFilters.from_date}
					to_date={this.state.searchFilters.to_date}
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
					context={this.state.context}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 350px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									context={this.state.context}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicVipPaymentGrid;
