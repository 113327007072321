import React, { Component } from "react";
import CompanyInfo from "./companyInfo";
import FacilityInfo from "./facilityInfo";
import PhysicianInfo from "./physicianInfo";
import UserInfo from "./userInfo";
import { clientOnboardProcess } from "../../services/clientOnboardServices/clientOnboardService";
import { phoneNumberFormatter } from "../../services/common/util";
import Swal from "sweetalert2";
import store from "../../redux/store";
import actions from "../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

class ClientOnboarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            errors: [],
            password: "",
            companyDetails: {
                name: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                contactName: "",
                contactEmail: "",
                contactPhone: ""
            },
            facilities: [
                {
                    name: "",
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zip: "",
                    contactName: "",
                    contactEmail: "",
                    contactPhone: "",
                    contactMobile: "",
                    fax: "",
                    faxType: "NoFax",
                    diagnosisCode: [],
                    testType: [],
                    firstInconclusiveRelease: false,
                    emailNotification: false,
                    isEnvironmental: false
                }
            ],
            physicians: [
                {
                    name: "",
                    npi: "",
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zip: "",
                    mobile: "",
                    facilityNames: [],
                    signature_image: ""
                }
            ],
            users: [
                {
                    firstName: "",
                    lastName: "",
                    email: "",
                    mobile: "",
                    facilityNames: []
                }
            ]
        };
    }

    componentDidMount() {}

    handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            this.confirmPassword();
        }
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    nextStep = () => {
        this.setState({
            step: this.state.step + 1
        });
    };

    previousStep = () => {
        this.setState({
            step: this.state.step - 1
        });
    };

    addArrayItem = (type) => {
        if (type === "facility") {
            let facilities = this.state.facilities;
            facilities.push({
                name: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                contactName: "",
                contactEmail: "",
                contactPhone: "",
                contactMobile: "",
                fax: "",
                faxType: "",
                emailNotification: false,
                isEnvironmental: false
            });
            this.setState({
                facilities: facilities
            });
        } else if (type === "physician") {
            let physicians = this.state.physicians;
            physicians.push({
                name: "",
                npi: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                mobile: "",
                facilityNames: [],
                signature_image: ""
            });
            this.setState({
                physicians: physicians
            });
        } else if (type === "user") {
            let users = this.state.users;
            users.push({
                firstName: "",
                lastName: "",
                email: "",
                mobile: "",
                facilityNames: []
            });
            this.setState({
                users: users
            });
        }
    };

    removeArrayItem = (type, index) => {
        if (type === "facility") {
            let facilities = this.state.facilities;
            facilities.splice(index, 1);
            this.setState({
                facilities: facilities
            });
        } else if (type === "physician") {
            let physicians = this.state.physicians;
            physicians.splice(index, 1);
            this.setState({
                physicians: physicians
            });
        } else if (type === "user") {
            let users = this.state.users;
            users.splice(index, 1);
            this.setState({
                users: users
            });
        }
    };

    handleCompanyChange = (e) => {
        let companyDetails = this.state.companyDetails;
        if (e.target.name === "contactPhone") {
            companyDetails[e.target.name] = phoneNumberFormatter(
                e.target.value,
                companyDetails[e.target.name]
            );
        } else {
            companyDetails[e.target.name] = e.target.value;
        }
        this.setState({ companyDetails: companyDetails });
    };

    handleArrayItemChange = (e, type, index) => {
        if (type === "facility") {
            let facilities = this.state.facilities;
            facilities.map((facility, i) => {
                if (index === i) {
                    if (
                        e.target.name === "contactPhone" ||
                        e.target.name === "fax" ||
                        e.target.name === "contactMobile"
                    ) {
                        facility[e.target.name] = phoneNumberFormatter(
                            e.target.value,
                            facility[e.target.name]
                        );
                    } else if (e.target.name === "firstInconclusiveRelease") {
                        facility[e.target.name] =
                            e.target.value === "true" ? true : false;
                    } else {
                        facility[e.target.name] = e.target.value;
                    }
                }
                return null;
            });
            this.setState({ facilities: facilities });
        } else if (type === "physician") {
            let physicians = this.state.physicians;
            if (
                e.target.name === "facilityNames" &&
                e.target.value.includes("all")
            ) {
                physicians.map((physician, i) => {
                    if (index === i) {
                        if (
                            physician[e.target.name].length ===
                            this.state.facilities.length
                        ) {
                            physician[e.target.name] = [];
                        } else {
                            let facilityNames = this.state.facilities.map(
                                (facility) => {
                                    return facility.name;
                                }
                            );
                            physician[e.target.name] = facilityNames;
                        }
                    }
                    return null;
                });
            } else {
                physicians.map((physician, i) => {
                    if (index === i) {
                        if (e.target.name === "mobile") {
                            physician[e.target.name] = phoneNumberFormatter(
                                e.target.value,
                                physician[e.target.name]
                            );
                        } else {
                            physician[e.target.name] = e.target.value;
                        }
                    }
                    return null;
                });
            }
            this.setState({ physicians: physicians });
        } else if (type === "user") {
            let users = this.state.users;
            if (
                e.target.name === "facilityNames" &&
                e.target.value.includes("all")
            ) {
                users.map((user, i) => {
                    if (index === i) {
                        if (
                            user[e.target.name].length ===
                            this.state.facilities.length
                        ) {
                            user[e.target.name] = [];
                        } else {
                            let facilityNames = this.state.facilities.map(
                                (facility) => {
                                    return facility.name;
                                }
                            );
                            user[e.target.name] = facilityNames;
                        }
                    }
                    return null;
                });
            } else {
                users.map((user, i) => {
                    if (index === i) {
                        if (e.target.name === "mobile") {
                            user[e.target.name] = phoneNumberFormatter(
                                e.target.value,
                                user[e.target.name]
                            );
                        } else {
                            user[e.target.name] = e.target.value;
                        }
                    }
                    return null;
                });
            }
            this.setState({ users: users });
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = () => {
        boundActions.startBlockUILoading();
        const clientOnboardInfo = {
            companyDetails: this.state.companyDetails,
            facilities: this.state.facilities,
            physicians: this.state.physicians,
            users: this.state.users
        };
        clientOnboardProcess(clientOnboardInfo)
            .then((res) => {
                boundActions.endBlockUILoading();
                if (res?.status === 200) {
                    this.nextStep();
                } else {
                    Swal.fire({
                        customClass: {
                            container:
                                window.localStorage.getItem("appTheme") ===
                                    "Dark" &&
                                /clinic|lims/.test(
                                    window.location.pathname.split("/")[1]
                                ) &&
                                "dark-swal"
                        },
                        title: res?.message || "Client onboarding failed!",
                        icon: "error"
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    toggleItem = (type, index) => {
        let { facilities } = this.state;
        switch (type) {
            case "isEnvironmental":
                facilities.map((facility, i) => {
                    if (i === index) {
                        facility.isEnvironmental = !facility.isEnvironmental;
                    }
                    return null;
                });
                this.setState({ facilities: facilities });
                break;
            case "emailNotification":
                facilities.map((facility, i) => {
                    if (i === index) {
                        facility.emailNotification =
                            !facility.emailNotification;
                    }
                    return null;
                });
                this.setState({ facilities: facilities });
                break;
            default:
                break;
        }
    };

    confirmPassword = () => {
        if (this.state.password === "BlueLotOnboarding") {
            this.setState({
                step: 2,
                password: ""
            });
        } else {
            this.setState({
                step: 1,
                password: ""
            });
        }
    };

    render() {
        return (
            <div className="content login-content">
                <div className="container-fluid">
                    <h2 className="text-center">Client Onboarding</h2>
                    <div className="row form-row">
                        <div className="col-md-8 offset-md-2">
                            {this.state.step === 1 && (
                                <div className="form-group">
                                    <label>
                                        Please input password to continue
                                    </label>
                                    <div style={{ display: "flex" }}>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            style={{
                                                width: "calc(100% - 100px)"
                                            }}
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            onKeyDown={this.handleKeyDown}
                                        />
                                        <button
                                            className="btn btn-primary ml-2 btn-pagebreak-next"
                                            onClick={this.confirmPassword}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            )}
                            {this.state.step === 2 && (
                                <div className="row form-row">
                                    <div className="col-12">
                                        <CompanyInfo
                                            companyDetails={
                                                this.state.companyDetails
                                            }
                                            handleCompanyChange={
                                                this.handleCompanyChange
                                            }
                                            toggleItem={this.toggleItem}
                                            nextStep={this.nextStep}
                                        />
                                    </div>
                                </div>
                            )}
                            {this.state.step === 3 && (
                                <div className="row form-row">
                                    <div className="col-12">
                                        <FacilityInfo
                                            facilities={this.state.facilities}
                                            toggleItem={this.toggleItem}
                                            addArrayItem={this.addArrayItem}
                                            removeArrayItem={
                                                this.removeArrayItem
                                            }
                                            handleArrayItemChange={
                                                this.handleArrayItemChange
                                            }
                                            nextStep={this.nextStep}
                                            previousStep={this.previousStep}
                                        />
                                    </div>
                                </div>
                            )}
                            {this.state.step === 4 && (
                                <div className="row form-row">
                                    <div className="col-12">
                                        <PhysicianInfo
                                            facilities={this.state.facilities}
                                            physicians={this.state.physicians}
                                            toggleItem={this.toggleItem}
                                            addArrayItem={this.addArrayItem}
                                            removeArrayItem={
                                                this.removeArrayItem
                                            }
                                            handleArrayItemChange={
                                                this.handleArrayItemChange
                                            }
                                            nextStep={this.nextStep}
                                            previousStep={this.previousStep}
                                        />
                                    </div>
                                </div>
                            )}
                            {this.state.step === 5 && (
                                <div className="row form-row">
                                    <div className="col-12">
                                        <UserInfo
                                            facilities={this.state.facilities}
                                            users={this.state.users}
                                            addArrayItem={this.addArrayItem}
                                            removeArrayItem={
                                                this.removeArrayItem
                                            }
                                            handleArrayItemChange={
                                                this.handleArrayItemChange
                                            }
                                            handleSubmit={this.handleSubmit}
                                            previousStep={this.previousStep}
                                        />
                                    </div>
                                </div>
                            )}
                            {this.state.step === 6 && (
                                <div
                                    className="row form-row"
                                    style={{ marginTop: "50px" }}
                                >
                                    <div className="col-12">
                                        <h3>
                                            Thanks for submitting the onboarding
                                            form. Your sales representative will
                                            get back to you with the next steps.
                                        </h3>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientOnboarding;
