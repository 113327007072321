import React, { Component, memo } from "react";
import { AgGridReact } from 'ag-grid-react';
import EditBtnCellRenderer from "./editBtnCellRenderer";
import moment from "moment";
//service calls
import { getAllFacilityData } from "../../../../services/clinicPortalServices/facilityServices";
import FacilitySearchMenu from "./facilitySearchMenu";
import { dateComparator, getFullAddress, getUserRole, getUserDetails } from "../../../../services/common/util";
import { auditEnums } from "../../../../services/common/constants";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicFacilityGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errors: [],
			columnDefs: [
				{
					headerName: "Actions",
					minWidth: 100,
					cellStyle: { textAlign: "center" },
					cellRenderer: "editBtnCellRenderer",
				},
				{
					headerName: "Status",
					minWidth: 100,
					sortable: true,
					resizable: true,
					cellStyle: { textAlign: "center" },
					valueGetter: function addColumns(params) {
						return params.data.facility.archived === "archived" ? "Archived" : "";
					}
				},
				{
					colId: 'last_order_released_date',
					headerName: 'Latest Orde Release Date',
					minWidth: 150,
					sortable: true,
					resizable: true,
					valueGetter: function addColumns(params) {
						return params.data.order && params.data.order.results ? moment(params.data.order.results.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY") : '';
					},
					comparator: dateComparator
				},
				{
					headerName: "Code",
					minWidth: 150,
					field: "facility.code",
					resizable: true,
				},
				{
					headerName: "Name",
					minWidth: 150,
					field: "facility.name",
					resizable: true,
				},
				{
					headerName: "Address",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.facility.address && params.data.facility.address.address1) {
							return getFullAddress(params.data.facility.address);
						} else {
							return "";
						}
					},
					cellRenderer: memo(params => {
						return <span><i className="fas fa-map-marker-alt"></i> {params.value ? params.value : ''}</span>
					})
				},
				{
					headerName: "Contact Name",
					minWidth: 150,
					field: "facility.contact_name",
					resizable: true,
				},
				{
					headerName: "Phone #",
					field: "facility.phone_no",
					minWidth: 150,
					resizable: true,
					cellRenderer: memo(params => {
						return <span><i className="fas fa-phone-alt"></i> {params.data.facility.phone_no ? params.data.facility.phone_no : ''}</span>
					})
				},
				{
					headerName: "Mobile #",
					field: "facility.contact_mobile",
					minWidth: 150,
					resizable: true,
					cellRenderer: memo(params => {
						return <span><i className="fas fa-phone-alt"></i> {params.data.facility.contact_mobile ? params.data.facility.contact_mobile : ''}</span>
					})
				},
				{
					headerName: "Email",
					field: "facility.contact_email",
					minWidth: 150,
					resizable: true,
					cellRenderer: memo(params => {
						return <span><i className="fas fa-envelope"></i> {params.data.facility.contact_email ? params.data.facility.contact_email : ''}</span>
					})
				},
				{
					headerName: "Company Name",
					minWidth: 150,
					field: "facility.company_id.name",
					resizable: true,
				},
				{
					headerName: "Fax #",
					minWidth: 150,
					resizable: true,
					field: "facility.fax_no",
				},
				{
					headerName: "Fax Type",
					field: "facility.fax_type",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Email Notifications",
					field: "facility.email_notifications_enabled",
					minWidth: 170,
					resizable: true,
					valueGetter: function (params) {
						return params.data.facility.email_notifications_enabled ? "Yes" : "No";
					},
				},
				{
					headerName: "Environmental Monitoring",
					field: "facility.environmental_monitoring_enabled",
					minWidth: 200,
					resizable: true,
					valueGetter: function (params) {
						return params.data.facility.environmental_monitoring_enabled ? "Yes" : "No";
					},
				},
			],
			components: {
				editBtnCellRenderer: EditBtnCellRenderer,
			},
			context: { componentParent: this },
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			facilityIds: [],
			rowClassRules: {
				'old-facility-row': function (params) {
					return params.data.facility.archived && params.data.facility.archived === "archived";
				},
			},
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
		let defaultSortModel = [
			{ colId: 'last_order_released_date', sort: 'asc' },
		];
		params.columnApi.applyColumnState({ state: defaultSortModel });
	};

	loadGridData = () => {
		this.gridApi.showLoadingOverlay();
		getAllFacilityData()
			.then((response) => {
				if (!response.data.length) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.setState({ rowData: response.data });
					let facilityIds = [];
					response.data.map(item => {
						facilityIds.push(item.facility._id);
						return null;
					});
					this.setState({ facilityIds: facilityIds });
					this.gridApi.hideOverlay();
				}
			});
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.FacilityGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.FacilityGridExported
		};
		createAudit(auditData);
	};

	render() {
		let columnDefs = /salesperson|audit|salesadmin|customerserviceuser/.test(getUserRole().toLowerCase()) ? this.state.columnDefs.filter(x => x.headerName !== 'Actions') : this.state.columnDefs;
		return (
			<div className="clinic-contain">
				<FacilitySearchMenu
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
					facilities={this.state.rowData}
					facilityIds={this.state.facilityIds}
					context={this.state.context}
				/>

				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									rowClassRules={this.state.rowClassRules}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									components={this.state.components}
									context={this.state.context}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicFacilityGrid;
