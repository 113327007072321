import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
	updateNotificationType,
	createNotificationType,
	getNotificationTypeDataById
} from "../../../../services/clinicPortalServices/notificationTypeService";
import { serviceConstants } from "../../../../services/common/constants";
import { Checkbox, MenuItem } from "@mui/material";
import { getUserDetails } from "../../../../services/common/util";
import { getUserAuthToken } from "../../../../services/common/util";
import toastr from 'toastr';

export default class NotificationTypeDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			notificationTypeId: this.props && this.props.notificationTypeId ? this.props.notificationTypeId : "",
			title: "",
			key: "",
			description: "",
			subject: "",
			send_email: false,
			send_sms: false,
			send_browser_notification: false,
			send_push_notification: false,
			errors: [],
			screenshot: '',
			screenshotFile: {
				filename: '',
				content: '',

			}
		};
	}

	componentDidMount() {
		if (this.state.notificationTypeId !== "") {
			this.loadDetails();
		}
	}

	loadDetails = () => {
		getNotificationTypeDataById(this.state.notificationTypeId).then(res => {
			this.setState({
				title: res.data.title,
				key: res.data.key,
				description: res.data.description,
				send_email: res.data.send_email,
				send_sms: res.data.send_sms,
				send_browser_notification: res.data.send_browser_notification,
				send_push_notification: res.data.send_push_notification,
				subject: res.data.subject,
				screenshot: res.data.screenfile ? `${serviceConstants.API_HOST_NAME}${res.data.screenfile}` : '',
				screenshotFile: {
					filename: '',
					content: '',
				}
			})
		})
	};

	handleClose = () => {
		this.setState({
			show: false
		});
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	toggleState = (type) => {
		if (type === 'send_email') {
			this.setState({ send_email: !this.state.send_email });
		} else if (type === 'send_sms') {
			this.setState({ send_sms: !this.state.send_sms });
		} else if (type === 'send_browser_notification') {
			this.setState({ send_browser_notification: !this.state.send_browser_notification })
		} else if (type === 'send_push_notification') {
			this.setState({ send_push_notification: !this.state.send_push_notification })
		}
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	onScreenShotPicker = () => {
		document.getElementById('file-screenshot').click();
	}

	onPickImage = (e) => {
		let self = this;
		const loadedFile = e.target.files[0];
		let reader = new FileReader();
		reader.onloadend = function () {
			let screenshotFile = {
				filename: loadedFile.name,
				content: this.result
			};
			self.setState({ screenshotFile: screenshotFile, screenshot: this.result });
		}
		reader.readAsDataURL(loadedFile);
	}

	saveChanges = () => {
		let errors = [];

		if (!this.state.title) {
			errors.push("title");
		}
		if (!this.state.key) {
			errors.push("key");
		}
		if (!this.state.description) {
			errors.push("description");
		}

		this.setState({ errors: errors });
		if (errors.length > 0) {
			return false;
		}

		let notificationTypeInfo = {
			_id: this.state.notificationTypeId,
			title: this.state.title,
			key: this.state.key,
			description: this.state.description,
			send_email: this.state.send_email,
			send_sms: this.state.send_sms,
			send_browser_notification: this.state.send_browser_notification,
			send_push_notification: this.state.send_push_notification,
			subject: this.state.subject,
			screenShot: this.state.screenshotFile.filename === "" ? null : this.state.screenshotFile,
		};
		if (this.state.notificationTypeId) {
			updateNotificationType(notificationTypeInfo)
				.then((response) => {
					if (response.RESULT === "ERROR") {
						toastr.warning(response.message);
					} else {
						this.props.context.componentParent.loadGridData();
						this.props.handleClose();
					}
				});
		} else {
			const userInfo = JSON.parse(getUserDetails());
			notificationTypeInfo.createdBy = userInfo.first_name + ' ' + userInfo.last_name;
			delete notificationTypeInfo._id;
			createNotificationType(notificationTypeInfo)
				.then((response) => {
					if (response.RESULT === "ERROR") {
						toastr.warning(response.message);
					} else {
						this.props.context.componentParent.loadGridData();
						this.props.handleClose();
					}
				});
		}
	};

	render() {
		return (
			<div>
				{/* <form> */}
				<div className="row form-row">
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
								Title <span className="text-danger"> *</span>{" "}
							</label>
							<input
								type="text"
								name="title"
								value={this.state.title}
								onChange={this.handleChange}
								required
								className={
									this.hasError("title")
										? "form-control is-invalid"
										: "form-control"
								}
							/>
							<div
								className={
									this.hasError("title") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
								Key <span className="text-danger"> *</span>{" "}
							</label>
							<input
								type="text"
								name="key"
								value={this.state.key}
								onChange={this.handleChange}
								required
								readOnly={this.state.notificationTypeId ? true : false}
								className={
									this.hasError("key")
										? "form-control is-invalid"
										: "form-control"
								}
							/>
							<div
								className={
									this.hasError("key") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="form-group">
							<label>
								Description <span className="text-danger"> *</span>{" "}
							</label>
							<textarea
								type="text"
								name="description"
								value={this.state.description}
								onChange={this.handleChange}
								required
								className={
									this.hasError("description")
										? "form-control is-invalid"
										: "form-control"
								}
							/>
							<div
								className={
									this.hasError("description") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<MenuItem onClick={() => this.toggleState('send_email')}>
								<Checkbox checked={this.state.send_email} />
								<div style={{ whiteSpace: "break-spaces" }}>Send Email</div>
							</MenuItem>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<MenuItem onClick={() => this.toggleState('send_sms')}>
								<Checkbox checked={this.state.send_sms} />
								<div style={{ whiteSpace: "break-spaces" }}>Send SMS</div>
							</MenuItem>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<MenuItem onClick={() => this.toggleState('send_browser_notification')}>
								<Checkbox checked={this.state.send_browser_notification} />
								<div style={{ whiteSpace: "break-spaces" }}>Send Browser Notification</div>
							</MenuItem>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<MenuItem onClick={() => this.toggleState('send_push_notification')}>
								<Checkbox checked={this.state.send_push_notification} />
								<div style={{ whiteSpace: "break-spaces" }}>Send Push Notification</div>
							</MenuItem>
						</div>
					</div>
					{
						this.state.send_email
						&&
						<div className="col-12">
							<div className="form-group">
								<label>
									Subject
								</label>
								<input
									type="text"
									name="subject"
									value={this.state.subject}
									onChange={this.handleChange}
									required
									className={
										this.hasError("subject")
											? "form-control is-invalid"
											: "form-control"
									}
								/>
								<div
									className={
										this.hasError("subject") ? "inline-errormsg" : "hidden"
									}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
					}
					<div className="col-12">
						<h5>Upload ScreenShot</h5>
						<div className="card notifyType_imageBar">
							<button className="nofityType_imagePicker" onClick={this.onScreenShotPicker}>Upload File</button>
							{
								this.state.screenshot
								&&
								<img className="mt-3" src={this.state.screenshotFile.filename ? this.state.screenshot : this.state.screenshot + '?' + new Date().getTime() + '&token=' + getUserAuthToken()} id="picked-screenshot" alt="ScreenShot" />
							}
							<input type="file" name="screenshot" id="file-screenshot" onChange={this.onPickImage} accept="image/*" />
						</div>
					</div>
				</div>
				<div className="row">
					<div
						className="col-12"
						style={{
							paddingTop: "10px",
							borderTop: "1px solid rgba(0,0,0,.2",
						}}
					>
						<Button
							style={{ float: "right", marginLeft: "10px" }}
							variant="primary"
							onClick={this.saveChanges}
						>
							Save Changes
						</Button>
						<Button
							style={{ float: "right" }}
							variant="secondary"
							onClick={this.props.handleClose}
						>
							Close
						</Button>
					</div>
				</div>
				{/* </form> */}
			</div>
		);
	}
}
