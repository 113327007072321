import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import EditBtnCellRenderer from "./editBtnCellRenderer";
//service calls
import { fetchFacilitiesForOrders } from "../../../../services/clinicPortalServices/facilityServices";
import SearchMenu from "./searchMenu";
import { getUserRole, getUserDetails } from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicFacilityTestManagementGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errors: [],
			columnDefs: [
				{
					headerName: "Actions",
					minWidth: 30,
					maxWidth: 100,
					cellStyle: { textAlign: "center" },
					cellRenderer: "editBtnCellRenderer",
				},
				{
					headerName: "Name",
					minWidth: 200,
					maxWidth: 400,
					field: "name",
					resizable: true,
				},
				{
					headerName: "Status",
					minWidth: 100,
					maxWidth: 100,
					sortable: true,
					resizable: true,
					cellStyle: { textAlign: "center" },
					valueGetter: function addColumns(params) {
						return params.data.archived === "archived" ? "Archived" : "";
					}
				},
				{
					headerName: "Available Tests",
					minWidth: 100,
					sortable: true,
					resizable: true,
					cellStyle: { textAlign: "center" },
					valueGetter: function addColumns(params) {
						let displayString = [];
						if (params.data.testIds && params.data.testIds.length) {
							params.data.testIds.map(test => {
								displayString.push(test.name);
								return null;
							})
						}
						return displayString.join(", ");
					}
				},
			],
			components: {
				editBtnCellRenderer: EditBtnCellRenderer,
			},
			context: { componentParent: this },
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
				resizable: true
			},
			rowData: [],
			facilityIds: [],
			rowClassRules: {
				'old-facility-row': function (params) {
					return params.data.archived && params.data.archived === "archived";
				},
			},
			tests: []
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
		let defaultSortModel = [
			{ colId: 'last_order_released_date', sort: 'asc' },
		];
		params.columnApi.applyColumnState({ state: defaultSortModel });
	};

	getRowHeight = (params) => {
		let maxCount = 1;
		return 40 * maxCount;
	}

	loadGridData = () => {
		this.gridApi.showLoadingOverlay();
		fetchFacilitiesForOrders()
			.then((response) => {
				if (!response.data.length) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.setState({ rowData: response.data });
					let facilityIds = [];
					response.data.map(item => {
						facilityIds.push(item._id);
						return null;
					});
					this.setState({ facilityIds: facilityIds });
					this.gridApi.hideOverlay();
				}
			});
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.FacilityPatientTestResultManagementGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.FacilityPatientTestResultManagementGridExported
		};
		createAudit(auditData);
	};

	render() {
		let columnDefs = /salesperson|audit|salesadmin|customerserviceuser/.test(getUserRole().toLowerCase()) ? this.state.columnDefs.filter(x => x.headerName !== 'Actions') : this.state.columnDefs;
		return (
			<div className="clinic-contain">
				<SearchMenu
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
					context={this.state.context}
				/>

				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={columnDefs}
									defaultColDef={this.state.defaultColDef}
									rowClassRules={this.state.rowClassRules}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									components={this.state.components}
									context={this.state.context}
								// getRowHeight={this.getRowHeight}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicFacilityTestManagementGrid;
