import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";

import SearchMenu from "./searchMenu";
import {
  dateComparator,
  defaultExcelExportParams,
} from "../../../services/common/util";

//service calls
import { searchHl7Messages } from "../../../services/clinicPortalServices/hl7MessageService";
import moment from "moment";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import Hl7MessageContentRenderer from "./hl7MessageContentRenderer";
import { ThemeContext } from "../../../theme/ThemeProvider";

class ClinicHl7MessageGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchFilters: {
        from_date: moment().startOf("week").format("YYYY-MM-DD") + "T00:00",
        to_date: moment().add(1, "days").format("YYYY-MM-DD") + "T00:00",
        dateRange: "week",
      },
      columnDefs: [
        {
          headerName: "Actions",
          minWidth: 50,
          cellRenderer: EditBtnCellRenderer,
        },
        {
          headerName: "Provider Name",
          minWidth: 150,
          field: "provider",
        },
        {
          headerName: "Mirth Channel Name",
          minWidth: 150,
          field: "mirth_channel_name",
        },
        {
          headerName: "Time Transmitted",
          field: "time_transmitted",
          minWidth: 150,
          valueGetter: (params) => {
            if (params.data.time_transmitted) {
              return moment(
                params.data.time_transmitted,
                "YYYYMMDDHHmmss"
              ).format("MM/DD/YYYY hh:mm A");
            }
          },
          comparator: dateComparator,
        },
        {
          headerName: "Direction",
          field: "is_received",
          minWidth: 100,
          valueGetter: (params) => {
            if (params.data.is_received) {
              return "Received";
            } else {
              return "Sent";
            }
          },
        },
        {
          headerName: "Message Type",
          minWidth: 100,
          field: "message_type",
        },
        {
          headerName: "Message Content",
          field: "message_content",
          minWidth: 400,
          cellRenderer: Hl7MessageContentRenderer,
        },
      ],
      defaultColDef: {
        flex: 1,
        filter: true,
        sortable: true,
        resizable: true,
      },
      rowData: [],
    };
  }
  getRowHeight = (params) => {
    if (params.data.update_count && params.data.update_count > 0) {
      return (
        params.api.getSizesForCurrentTheme().rowHeight *
        (params.data.update_count + 1)
      );
    } else {
      return false;
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
  };

  loadGridData = () => {
    this.gridApi.showLoadingOverlay();
    const filter = {
      createdAt: {
        $gte: this.state.searchFilters.from_date,
        $lt: this.state.searchFilters.to_date,
      },
    };
    searchHl7Messages(filter).then((data) => {
      this.setState({ rowData: data.data });
      if (!data.data.length) {
        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    });
  };

  onFilterTextChange = (e) => {
    this.gridApi.setQuickFilter(e.target.value);
  };

  clearFilter = () => {
    let filters = this.state.searchFilters;
    filters.from_date =
      moment().subtract(7, "days").format("YYYY-MM-DD") + "T00:00";
    filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
    filters.dateRange = "week";
    this.setState({ searchFilters: filters });

    this.gridApi.setFilterModel(null);
    this.gridApi.setQuickFilter(null);
    document.getElementById("reset-form").value = "";
    this.loadGridData();
  };

  updateDateRange = (dateRange) => {
    const filters = this.state.searchFilters;
    if (filters.dateRange !== dateRange) {
      filters.dateRange = dateRange;
      switch (dateRange) {
        case "today":
          filters.to_date =
            moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
          filters.from_date = moment().format("YYYY-MM-DD") + "T00:00";
          break;
        case "week":
          filters.to_date =
            moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
          filters.from_date =
            moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
          break;
        case "month":
          filters.to_date =
            moment().add(1, "days").format("YYYY-MM-DD") + "T00:00";
          filters.from_date =
            moment().startOf("month").format("YYYY-MM-DD") + "T00:00";
          break;
        default:
          break;
      }
      this.setState({ searchFilters: filters });
      if (dateRange !== "custom") {
        this.loadGridData();
      }
    }
  };

  setDateRange = (searchFilters) => {
    if (
      moment(searchFilters.to_date).format("YYYY-MM-DD") ===
      moment().add(1, "days").format("YYYY-MM-DD")
    ) {
      if (
        moment(searchFilters.from_date).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
      ) {
        searchFilters.dateRange = "today";
      } else if (
        moment(searchFilters.from_date).format("YYYY-MM-DD") ===
        moment().startOf("week").format("YYYY-MM-DD")
      ) {
        searchFilters.dateRange = "week";
      } else if (
        moment(searchFilters.from_date).format("YYYY-MM-DD") ===
        moment().startOf("month").format("YYYY-MM-DD")
      ) {
        searchFilters.dateRange = "month";
      } else {
        searchFilters.dateRange = "custom";
      }
    } else {
      searchFilters.dateRange = "custom";
    }
  };

  handleDateFiltersChange = (dateTime, type) => {
    const filters = this.state.searchFilters;
    let filterIsValid = true;
    if (type === "from_date") {
      if (moment(filters.to_date).isAfter(dateTime)) {
        filters.from_date = dateTime;
      } else {
        filterIsValid = false;
      }
    } else {
      if (moment(dateTime).isAfter(filters.from_date)) {
        filters.to_date = dateTime;
      } else {
        filterIsValid = false;
      }
    }
    if (filterIsValid) {
      this.setDateRange(filters);
      this.setState({ searchFilters: filters });
      this.loadGridData();
    }
  };

  onBtnExport = () => {
    this.gridApi.exportDataAsExcel({});
  };

  render() {
    return (
      <div className="clinic-contain">
        <SearchMenu
          handleDateFiltersChange={this.handleDateFiltersChange}
          updateDateRange={this.updateDateRange}
          from_date={this.state.searchFilters.from_date}
          to_date={this.state.searchFilters.to_date}
          date_range={this.state.searchFilters.dateRange}
          onFilterTextChange={this.onFilterTextChange}
          clearFilter={this.clearFilter}
          onBtnExport={this.onBtnExport}
        />
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 250px)",
            padding: "15px 15px 15px 15px",
          }}
        >
          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className="ag-theme-alpine"
          >
            <ThemeContext.Consumer>
              {({ themeName }) => (
                <div
                  id="myGrid"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  className={
                    themeName === "Light"
                      ? "ag-theme-alpine"
                      : "ag-theme-alpine-dark"
                  }
                >
                  <AgGridReact
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    onGridReady={this.onGridReady}
                    rowData={this.state.rowData}
                    pagination={true}
                    paginationAutoPageSize={true}
                    getRowHeight={this.getRowHeight}
                    defaultExcelExportParams={defaultExcelExportParams}
                  />
                </div>
              )}
            </ThemeContext.Consumer>
          </div>
        </div>
      </div>
    );
  }
}

export default ClinicHl7MessageGrid;
