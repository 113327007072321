import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import LabInventoryDetails from "./labInventoryDetails";
import Swal from "sweetalert2";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { deleteLabInventory, updateLabInventory } from "../../../../services/clinicPortalServices/labInventoryService";
import { TextField, IconButton, InputAdornment, Autocomplete } from "@mui/material";
import { dateComparator, numberComparator, getUserFullName, getUserDetails } from "../../../../services/common/util";
import moment from "moment";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import { AgGridReact } from "ag-grid-react";
import { auditEnums } from "../../../../services/common/constants";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import EventIcon from '@mui/icons-material/Event';

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			labInventoryId: props.data._id,
			showManualUpdatePopup: false,
			showTrackingHistoryPopup: false,
			showRecordOrderedInventoryPopup: false,
			pickerOpened: false,
			ordered_date: props.data.ordered_date ? moment(props.data.ordered_date, "YYYYMMDDHHmmss") : moment(),
			temp_ordered_date: props.data.ordered_date ? moment(props.data.ordered_date, "YYYYMMDDHHmmss") : moment(),
			ordered_amount: "",
			ordered_vendor_index: "",
			currentAmount: this.props.data.current_amount
		};
	}

	handleManualUpdate = () => {
		this.setState({
			showManualUpdatePopup: true,
			currentAmount: this.props.data.current_amount
		})
	}

	handleCloseManualUpdatePopup = () => {
		this.setState({ showManualUpdatePopup: false })
	}

	updateAmount = () => {
		let offset = Number(this.state.currentAmount) - Number(this.props.data.current_amount);
		let tracking_history = this.props.data.tracking_history;
		tracking_history.push({
			offset: Number(offset),
			user_name: getUserFullName(),
			time: moment().format("YYYYMMDDHHmmss"),
			note: "Updated Manually",
		})
		let labInventory = {
			_id: this.props.data._id,
			name: this.props.data.name,
			current_amount: this.state.currentAmount,
			tracking_history: tracking_history
		}
		updateLabInventory(labInventory).then(res => {
			this.props.context.componentParent.loadGridData();
			this.handleCloseManualUpdatePopup();
		})
	}

	handleShowTrackingHistory = () => {
		this.setState({
			showTrackingHistoryPopup: true
		})
	}

	handleCloseTrackingHistoryPopup = () => {
		this.setState({
			showTrackingHistoryPopup: false
		})
	}

	handleShowRecordOrderedInventoryPopup = () => {
		this.setState({
			showRecordOrderedInventoryPopup: true,
			ordered_date: this.props.data.ordered_date ? moment(this.props.data.ordered_date, "YYYYMMDDHHmmss") : moment(),
			temp_ordered_date: this.props.data.ordered_date ? moment(this.props.data.ordered_date, "YYYYMMDDHHmmss") : moment(),
			ordered_amount: this.props.data.ordered_amount ? this.props.data.ordered_amount : "",
			ordered_vendor_index: this.props.data.ordered_vendor_index ? this.props.data.ordered_vendor_index : "",
		}, () => {
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		})
	}

	handleCloseRecordOrderedInventoryPopup = () => {
		this.setState({
			showRecordOrderedInventoryPopup: false
		})
	}

	handleChangeTime = (value) => {
		this.setState({ ordered_date: value, pickerOpened: false });
	}

	recordOrderedInventory = () => {
		let labInventory = {
			_id: this.props.data._id,
			name: this.props.data.name,
			ordered_amount: this.state.ordered_amount,
			ordered_date: moment(this.state.ordered_date).format("YYYYMMDDHHmmss"),
			ordered_vendor_index: this.state.ordered_vendor_index
		}
		updateLabInventory(labInventory).then(res => {
			this.props.context.componentParent.loadGridData();
			this.handleCloseRecordOrderedInventoryPopup();
		})
	}

	removeOrderedInventory = () => {
		let labInventory = {
			_id: this.props.data._id,
			name: this.props.data.name,
			ordered_amount: "",
			ordered_date: "",
			ordered_vendor_index: ""
		}
		updateLabInventory(labInventory).then(res => {
			this.props.context.componentParent.loadGridData();
			this.handleCloseRecordOrderedInventoryPopup();
		})
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridColumnApi.applyColumnState({
			state: [
				{
					colId: "time",
					sort: "desc",
				},
			],
			defaultState: { sort: null },
		});
	}

	onBtnExportForTrackingHistory = () => {
		this.gridApi.exportDataAsExcel({
			fileName: "LabInventory_" + this.props.data.name + "_Tracking_History_" + moment().format("YYYY_MM_DD_HH_mm_ss") + ".xlsx"
		});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.LabInventoryTrackingHistoryGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.LabInventoryTrackingHistoryGridExported
		};
		createAudit(auditData);
	}

	handleDelete = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this inventory!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				deleteLabInventory({ _id: this.state.labInventoryId, name: this.props.data.name }).then(res => {
					this.props.context.componentParent.loadGridData();
				})
			}
		});
	};

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Inventory
		</Tooltip>
	);

	renderTooltipReceive = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Update Amount
		</Tooltip>
	);

	renderTooltipTrackingHistory = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Show Tracking History
		</Tooltip>
	)

	renderRecordOrderedInventory = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Record Ordered Inventory
		</Tooltip>
	)

	renderTooltipDelete = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Delete Inventory
		</Tooltip>
	);

	render() {
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<button onClick={this.handleShow} className="edit-order-btn">
						<i className="fas fa-pen"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipReceive}
				>
					<button onClick={this.handleManualUpdate} className="edit-order-btn">
						<i className="fas fa-calculator"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderRecordOrderedInventory}
				>
					<button onClick={this.handleShowRecordOrderedInventoryPopup} className="edit-order-btn">
						<i className="fas fa-shopping-cart"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipTrackingHistory}
				>
					<button onClick={this.handleShowTrackingHistory} className="edit-order-btn">
						<i className="fas fa-list"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipDelete}
				>
					<button onClick={this.handleDelete} className="edit-order-btn">
						<i className="fas fa-trash"></i>
					</button>
				</OverlayTrigger>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Inventory Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<LabInventoryDetails
							labInventoryId={this.state.labInventoryId}
							context={this.props.context}
							handleClose={this.handleClose}
						/>
					</Modal.Body>
				</ModalStyled>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showManualUpdatePopup}
					onHide={this.handleCloseManualUpdatePopup}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Current Amount</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="form-group col-sm-12">
								<TextField
									label="Current Amount"
									variant="outlined"
									type="number"
									value={this.state.currentAmount}
									onChange={(e) => {
										this.setState({
											currentAmount: e.target.value
										})
									}}
								/>
							</div>
						</div>
						<div className="row">
							<div
								className="col-12"
								style={{
									paddingTop: "10px",
									borderTop: "1px solid rgba(0,0,0,.2",
								}}
							>
								<Button
									style={{ float: "right", marginLeft: "10px" }}
									variant="primary"
									onClick={this.updateAmount}
									disabled={this.state.currentAmount ? false : true}
								>
									Save Changes
								</Button>
								<Button
									style={{ float: "right" }}
									variant="secondary"
									onClick={this.handleCloseManualUpdatePopup}
								>
									Close
								</Button>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showRecordOrderedInventoryPopup}
					onHide={this.handleCloseRecordOrderedInventoryPopup}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Record Ordered Inventory</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="form-group col-sm-12">
								<MobileDateTimePicker
									value={this.state.pickerOpened ? this.state.temp_ordered_date : this.state.ordered_date}
									onChange={(dateTime) => this.setState({ temp_ordered_date: dateTime.$d })}
									onClose={() => this.setState({ pickerOpened: false })}
									onOpen={() => this.setState({ temp_ordered_date: this.state.ordered_date, pickerOpened: true })}
									onAccept={(dateTime) => this.handleChangeTime(dateTime.$d)}
									label="Ordered Date"
									renderInput={props => <TextField
										{...props}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton edge="end">
														<EventIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
									/>}
								/>
							</div>
							<div className="form-group col-sm-12">
								<TextField
									label="Ordered Amount"
									variant="outlined"
									type="number"
									value={this.state.ordered_amount}
									onChange={(e) => {
										this.setState({
											ordered_amount: e.target.value
										})
									}}
								/>
							</div>
							<div className="form-group col-sm-12">
								<Autocomplete
									options={this.props.data.vendors && this.props.data.vendors.length ? this.props.data.vendors : []}
									value={this.state.ordered_vendor_index !== "" ? this.props.data.vendors[this.state.ordered_vendor_index] : ""}
									onChange={(event, newValue) => {
										let index = "";
										this.props.data.vendors.map((item, i) => {
											if (item === newValue) {
												index = i;
											}
											return null;
										})
										this.setState({
											ordered_vendor_index: index
										})
									}}
									getOptionLabel={(option) => option ? (option.vendor_name + (option.vendor_contact ? (" - " + option.vendor_contact) : "")) : ""}
									renderInput={(params) => <TextField {...params} label="Ordered Vendor" variant="outlined" />}
								/>
							</div>
						</div>
						<div className="row">
							<div
								className="col-12"
								style={{
									paddingTop: "10px",
									borderTop: "1px solid rgba(0,0,0,.2",
								}}
							>
								<Button
									style={{ float: "right", marginLeft: "10px" }}
									variant="primary"
									onClick={this.recordOrderedInventory}
									disabled={this.state.ordered_date && this.state.ordered_amount ? false : true}
								>
									Save Changes
								</Button>
								{
									this.props.data.ordered_amount && this.props.data.ordered_date
									&&
									<Button
										style={{ float: "right", marginLeft: "10px" }}
										variant="info"
										onClick={this.removeOrderedInventory}
									>
										Cancel Ordering
									</Button>
								}
								<Button
									style={{ float: "right" }}
									variant="secondary"
									onClick={this.handleCloseRecordOrderedInventoryPopup}
								>
									Close
								</Button>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showTrackingHistoryPopup}
					onHide={this.handleCloseTrackingHistoryPopup}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Tracking History</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="form-group col-sm-12 d-flex justify-content-end">
								<button
									className="btn btn-primary"
									onClick={() => this.onBtnExportForTrackingHistory()}
								>
									<i className="fa fa-file-excel-o" aria-hidden="true"></i> Export
								</button>
							</div>
							<div className="form-group col-sm-12">
								{
									<ThemeContext.Consumer>
										{({ themeName }) => (
											<div
												id="currentListGrid"
												style={{
													height: "350px",
													width: "100%",
												}}
												className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
											>
												<AgGridReact
													columnDefs={[
														{
															headerName: "Time",
															field: "time",
															resizable: true,
															valueGetter: params => {
																return moment(params.data.time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A")
															},
															maxWidth: "200",
															comparator: dateComparator
														},
														{
															headerName: "Offset",
															field: "offset",
															resizable: true,
															maxWidth: "100",
															comparator: numberComparator
														},
														{
															headerName: "User Name",
															field: "user_name",
															resizable: true,
															maxWidth: "200"
														},
														{
															headerName: "Note",
															field: "note",
															resizable: true,
														}
													]}
													defaultColDef={{
														flex: 1,
														filter: true,
														sortable: true,
														resizable: true
													}}
													masterDetail={true}
													rowData={this.props.data.tracking_history}
													pagination={true}
													paginationPageSize={20}
													paginationNumberFormatter={(params) => {
														return "[" + params.value.toLocaleString() + "]";
													}}
													onGridReady={this.onGridReady}
												/>
											</div>
										)}
									</ThemeContext.Consumer>
								}
							</div>
						</div>
						<div className="row">
							<div
								className="col-12"
								style={{
									paddingTop: "10px",
									borderTop: "1px solid rgba(0,0,0,.2",
								}}
							>
								<Button
									style={{ float: "right" }}
									variant="secondary"
									onClick={this.handleCloseTrackingHistoryPopup}
								>
									Close
								</Button>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
