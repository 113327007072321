import React, { Component } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { Link } from "react-router-dom";

class DashboardBreadcrumb extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="breadcrumb-bar">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-12 col-12">
							<nav aria-label="breadcrumb" className="page-breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/">Home</Link>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Dashboard
									</li>
								</ol>
							</nav>
							<div style={{ display: "flex" }}>
								<h2 className="breadcrumb-title">Dashboard</h2>
								<FormControlLabel
									value="includeArchived"
									control={<Switch checked={this.props.isArchived} onChange={this.props.onIsArchivedInformation} />}
									label="Show Archives"
									labelPlacement="end"
									className="m-0 show-archives-control breadcrumb-control"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default DashboardBreadcrumb;