import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import EditBtnCellRenderer from "./editBtnCellRenderer";
import DescriptionCellRenderer from "./descriptionCellRenderer";
import SubjectCellRenderer from "./subjectCellRenderer";
import ScreenShotCellRenderer from "./screenShotCellRenderer";
//service calls
import { fetchNotificationTypes } from "../../../../services/clinicPortalServices/notificationTypeService";
import SearchMenu from "./searchMenu";
import { getUserRole, getUserDetails } from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicNotificationTypeGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			columnDefs: [
				{
					headerName: "Actions",
					minWidth: 100,
					maxWidth: 100,
					cellStyle: { textAlign: "center" },
					cellRenderer: "editBtnCellRenderer",
				},
				{
					headerName: "Title",
					minWidth: 150,
					resizable: true,
					field: "title",
				},
				{
					headerName: "Key",
					minWidth: 150,
					resizable: true,
					field: "key",
				},
				{
					headerName: "Description",
					minWidth: 150,
					resizable: true,
					field: "description",
					cellRenderer: "descriptionCellRenderer"
				},
				{
					headerName: "Email Content",
					minWidth: 150,
					resizable: true,
					field: 'screenfile',
					cellRenderer: 'screenShotCellRenderer',
				},
				{
					headerName: "Send Email",
					minWidth: 100,
					resizable: true,
					valueGetter: params => {
						if (params.data.send_email) {
							return "YES";
						} else {
							return "NO";
						}
					}
				},
				{
					headerName: "Send SMS",
					minWidth: 100,
					resizable: true,
					valueGetter: params => {
						if (params.data.send_sms) {
							return "YES";
						} else {
							return "NO";
						}
					}
				},
				{
					headerName: "Send Browser Notification",
					minWidth: 120,
					resizable: true,
					valueGetter: params => {
						if (params.data.send_browser_notification) {
							return "YES";
						} else {
							return "NO";
						}
					}
				},
				{
					headerName: "Send Push Notification",
					minWidth: 120,
					resizable: true,
					valueGetter: params => {
						if (params.data.send_push_notification) {
							return "YES";
						} else {
							return "NO";
						}
					}
				},
				{
					headerName: "Subject",
					minWidth: 150,
					resizable: true,
					field: "subject",
					cellRenderer: "subjectCellRenderer"
				},
			],
			components: {
				editBtnCellRenderer: EditBtnCellRenderer,
				descriptionCellRenderer: DescriptionCellRenderer,
				screenShotCellRenderer: ScreenShotCellRenderer,
				subjectCellRenderer: SubjectCellRenderer
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			context: { componentParent: this },
			facilities: []
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		fetchNotificationTypes()
			.then((response) => {
				this.setState({ rowData: response.data });
			});
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.NotificationTypeGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.NotificationTypeGridExported
		};
		createAudit(auditData);
	};

	render() {
		let columnDefs = /facilityadmin|^admin/.test(getUserRole().toLowerCase()) ? this.state.columnDefs.filter(x => !['Actions', 'Email Template ID'].includes(x.headerName)) : this.state.columnDefs;
		return (
			<div className="clinic-contain">
				<SearchMenu
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
					context={this.state.context}
					facilities={this.state.facilities}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									components={this.state.components}
									context={this.state.context}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicNotificationTypeGrid;
