import { apiInterfaceForJson } from "../common/apiManager";

export const fetchPhysicians = (facilityId) => {
    return apiInterfaceForJson("/physician/v1/search", "POST", { facility_id: facilityId });
};

export const fetchPhysiciansUnlocked = (facilityId) => {
    return apiInterfaceForJson("/physician/v1/searchUnlocked", "POST", { facility_id: facilityId });
};

export const getPhysicianData = () => {
    return apiInterfaceForJson("/physician/v1", "GET");
};

export const getPhysicianDataByFilter = (filter) => {
    return apiInterfaceForJson("/physician/v1/getPhysicianByFilter", "POST", filter);
};

export const createPhysician = (physicianDetails) => {
    let physicianInfo = {
        "address": {
            'address1': physicianDetails.address1,
            'address2': physicianDetails.address2,
            'city': physicianDetails.city,
            'state': physicianDetails.state,
            'zip': physicianDetails.zip,
            'country': physicianDetails.country
        },
        'last_name': physicianDetails.lastName,
        'first_name': physicianDetails.firstName,
        'npi': physicianDetails.npi,
        'mobile': physicianDetails.mobile,
        'code': physicianDetails.code,
        'facilityIds': physicianDetails.facilityIds

    };
    if (physicianDetails.signature_image) {
        physicianInfo.signature_image = physicianDetails.signature_image;
    }
    return apiInterfaceForJson("/physician/v1/", "POST", physicianInfo);
};

export const updatePhysician = (physicianDetails) => {
    let updatePhysicianInfo = {
        "address": {
            'address1': physicianDetails.address1,
            'address2': physicianDetails.address2,
            'city': physicianDetails.city,
            'state': physicianDetails.state,
            'zip': physicianDetails.zip,
            'country': physicianDetails.country
        },
        '_id': physicianDetails.id,
        'last_name': physicianDetails.lastName,
        'first_name': physicianDetails.firstName,
        'npi': physicianDetails.npi,
        'mobile': physicianDetails.mobile,
        'code': physicianDetails.code,
        'facilityIds': physicianDetails.facilityIds
    };
    if (physicianDetails.signature_image) {
        updatePhysicianInfo.signature_image = physicianDetails.signature_image;
    }
    return apiInterfaceForJson("/physician/v1/", "PUT", updatePhysicianInfo);
};

export const deletePhysician = (physicianDetails) => {
    return apiInterfaceForJson("/physician/v1/delete", "POST", physicianDetails);
};

export const lockPhysician = (id) => {
    return apiInterfaceForJson("/physician/v1/lockPhysician", "POST", { id: id });
}

export const unLockPhysician = (id) => {
    return apiInterfaceForJson("/physician/v1/unLockPhysician", "POST", { id: id });
}