import React, { Component } from "react";
import { authenticateAndFetchUserDetails } from "../../../services/clinicPortalServices/loginService";
import {
    isUserLoggedIn,
    getUserRole,
    getPatientAuthToken,
    setCookie
} from "../../../services/common/util";
import { logoutPatient } from "../../../services/patientPortalServices/loginService";
import { updateDevice } from "../../../services/clinicPortalServices/userService";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { dashboardLinks } from "../../../services/common/optionsData";
import moment from "moment";
import { BLUELOT_LOGO } from "../../common/imgLogoes";

class ClinicPortalLoginContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            userName: "",
            id: "",
            token: "",
            isPasswordIncorrect: false,
            isUserNameIncorrect: false,
            isDeactivated: false,
            loading: false
        };
    }
    componentDidMount() {
        if (isUserLoggedIn()) {
            window.location.href = dashboardLinks[getUserRole()]
                ? dashboardLinks[getUserRole()]
                : "/clinic/orders";
        }
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleLogin = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        authenticateAndFetchUserDetails(
            this.state.userName,
            this.state.password
        ).then((response) => {
            const userInfo = response.data;
            this.setState({ loading: false });
            if (userInfo) {
                if (userInfo === "password_incorrect") {
                    this.setState({
                        isPasswordIncorrect: true,
                        isUserNameIncorrect: false,
                        isDeactivated: false
                    });
                } else if (userInfo === "user_name_incorrect") {
                    this.setState({
                        isPasswordIncorrect: false,
                        isUserNameIncorrect: true,
                        isDeactivated: false
                    });
                } else if (userInfo === "user_deactivated") {
                    this.setState({
                        isPasswordIncorrect: false,
                        isUserNameIncorrect: false,
                        isDeactivated: true
                    });
                }
                // If the user does not require 2FA
                if (userInfo.user && !userInfo.user.require2FA) {
                    if (
                        userInfo.user.device_id &&
                        userInfo.user.device_id !== ""
                    ) {
                        Swal.fire({
                            text: "You are already logged in another device or web browser, Close the other session?",
                            icon: "info",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            customClass: {
                                container:
                                    window.localStorage.getItem("appTheme") ===
                                        "Dark" &&
                                    /clinic|lims/.test(
                                        window.location.pathname.split("/")[1]
                                    ) &&
                                    "dark-swal",
                                cancelButton: "order-1",
                                confirmButton: "order-2"
                            }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.saveUser(userInfo);
                            } else {
                                this.setState({ loading: false });
                            }
                        });
                    } else {
                        this.saveUser(userInfo);
                    }
                } else if (userInfo.user) {
                    this.props.history.push(
                        "/clinic_security/" + userInfo.user._id
                    );
                }
            }
        });
    };
    saveUser(userInfo) {
        // update device id
        if (getPatientAuthToken()) {
            logoutPatient();
        }
        let newDeviceId = this.makeid(100);
        const facilityIds = userInfo.user.facilityIds.map((facility) => {
            return facility._id;
        });
        window.localStorage.clear();
        window.localStorage.setItem("AUTH-TOKEN", userInfo.token);
        setCookie();
        window.localStorage.setItem("DEVICE_ID", newDeviceId);
        window.localStorage.setItem("USER_ID", userInfo.user._id);
        window.localStorage.setItem(
            "USER_DETAILS",
            JSON.stringify(userInfo.user)
        );
        window.localStorage.setItem(
            "FACILITY_IDS",
            JSON.stringify(facilityIds)
        );
        window.localStorage.setItem("USER_ROLE", userInfo.user.role);
        window.localStorage.setItem("popUpShows", "no");
        let userDeviceInfo = {
            id: userInfo.user._id,
            deviceId: newDeviceId
        };

        updateDevice(userDeviceInfo).then((response) => {
            if (response.data.RESULT === "ERROR") {
                console.log("error");
                window.localStorage.clear();
            } else {
                this.setState({
                    isPasswordIncorrect: false,
                    isUserNameIncorrect: false,
                    isDeactivated: false,
                    loading: false
                });
                window.localStorage.setItem("AUTH-TOKEN", response.data.token);
                setCookie();
                if (response.data.isPatientDuplicated) {
                    this.checkPatientDuplicated(userInfo.user.role);
                } else if (
                    response.data.maintenanceSlots &&
                    response.data.maintenanceSlots.length
                ) {
                    this.showMaintenanceItemRemindingPopup(
                        response.data.maintenanceSlots,
                        userInfo.user.role
                    );
                } else {
                    this.goMainPage(userInfo.user.role);
                }
            }
        });
    }

    goMainPage(role) {
        window.location.href = dashboardLinks[role]
            ? dashboardLinks[role]
            : "/clinic/orders";
    }

    checkPatientDuplicated = (role) => {
        Swal.fire({
            title: "Possible Patient Duplicates",
            text: "Potential patient duplicates found. Do you want to check them now?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = "/clinic/patientsDuplicated";
            } else {
                this.goMainPage(role);
            }
        });
    };

    showMaintenanceItemRemindingPopup = (slots, role) => {
        let htmlContent = "";
        slots.map((slot) => {
            htmlContent +=
                "Time: " +
                moment(slot.start_time, "YYYYMMDDHHmm").format("hh:mm A") +
                ", " +
                slot.maintenanceEvent_id.item_name +
                " - " +
                slot.maintenanceEvent_id.serial_number +
                "<br/>";
            return null;
        });
        htmlContent += "<br/>Do you want to check them now?";
        Swal.fire({
            title: "Maintenance Required",
            html: htmlContent,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = "/clinic/maintenance";
            } else {
                this.goMainPage(role);
            }
        });
    };

    makeid(length) {
        let result = "";
        let characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    }

    render() {
        return (
            <div className="content login-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="account-content">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-md-12 col-lg-6 my-4 d-flex justify-content-center">
                                        <img src={BLUELOT_LOGO} alt="login" />
                                    </div>
                                    <div className="col-md-12 col-lg-6 login-right mb-5">
                                        <form onSubmit={this.handleLogin}>
                                            <div className="form-group">
                                                <label className="font-weight-bold">
                                                    User Name{" "}
                                                    <span className="text-danger">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="userName"
                                                    value={this.state.userName}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-bold">
                                                    Password{" "}
                                                    <span className="text-danger">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            {this.state.isUserNameIncorrect && (
                                                <div
                                                    className=" btn-block "
                                                    style={{
                                                        color: "red",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    User Name is incorrect
                                                </div>
                                            )}
                                            {this.state.isPasswordIncorrect && (
                                                <div
                                                    className=" btn-block "
                                                    style={{
                                                        color: "red",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    Password is incorrect
                                                </div>
                                            )}
                                            {this.state.isDeactivated && (
                                                <div
                                                    className=" btn-block "
                                                    style={{
                                                        color: "red",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    Your account is deactivated
                                                </div>
                                            )}
                                            <button
                                                className="btn btn-primary btn-block btn-lg login-btn"
                                                type="submit"
                                                disabled={this.state.loading}
                                            >
                                                {this.state.loading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{
                                                            marginRight: "5px"
                                                        }}
                                                    ></i>
                                                )}
                                                {this.state.loading && (
                                                    <span>
                                                        Authenticating Please
                                                        wait
                                                    </span>
                                                )}
                                                {!this.state.loading && (
                                                    <span>Login</span>
                                                )}
                                            </button>
                                        </form>
                                        <div
                                            style={{
                                                marginTop: "15px",
                                                color: "blue",
                                                cursor: "pointer",
                                                float: "right"
                                            }}
                                        >
                                            <Link to="/clinic_forgetPassword">
                                                Forgot Password ?
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClinicPortalLoginContainer;
