import React, { Component, memo } from "react";
import { AgGridReact } from 'ag-grid-react';
import EditBtnCellRenderer from "./editBtnCellRenderer";
//service calls
import { getPhysicianData } from "../../../../services/clinicPortalServices/physicianServices";
import PhysicianSearchMenu from "./physicianSearchMenu";
import { getFullAddress, getUserRole, getUserDetails } from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicPhysicianGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			columnDefs: [
				{
					headerName: "Actions",
					minWidth: 100,
					maxWidth: 100,
					cellStyle: { textAlign: "center" },
					cellRenderer: "editBtnCellRenderer",
				},
				{
					headerName: "Status",
					minWidth: 50,
					sortable: true,
					resizable: true,
					valueGetter: function addColumns(params) {
						return params.data.locked === "locked" ? "Locked" : "";
					}
				},
				{
					headerName: "Name",
					minWidth: 150,
					resizable: true,
					valueGetter: function addColumns(params) {
						return params.data.first_name + " " + params.data.last_name;
					},
				},
				{
					headerName: "NPI",
					minWidth: 150,
					field: "npi",
					resizable: true,
				},
				{
					headerName: "Address",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.address && params.data.address.address1) {
							return getFullAddress(params.data.address)
						} else {
							return "";
						}
					},
					cellRenderer: memo(params => {
						return <span><i className="fas fa-map-marker-alt"></i> {params.value ? params.value : ""}</span>
					})
				},
				{
					headerName: "Mobile #",
					field: "mobile",
					minWidth: 150,
					resizable: true,
					cellRenderer: memo(params => {
						return <span><i className="fas fa-phone-alt"></i> {params.data.mobile ? params.data.mobile : ""}</span>
					})
				},
				{
					headerName: "Facility",
					minWidth: 150,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.facilityIds.length) {
							let facilityNames = [];
							params.data.facilityIds.map(facility => {
								facilityNames.push(facility.name);
								return null;
							})
							return facilityNames.join(', ');
						} else {
							return "";
						}
					},
				},
				{
					headerName: "Has Signature?",
					maxWidth: 200,
					field: "signature_image",
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.signature_image) {
							return "YES";
						} else {
							return "NO";
						}
					},
				},
			],
			components: {
				editBtnCellRenderer: EditBtnCellRenderer,
			},

			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			context: { componentParent: this },
			rowClassRules: {
				'locked-physician-row': function (params) {
					return params.data.locked && params.data.locked === "locked";
				},
			},
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		getPhysicianData()
			.then((response) => {
				this.setState({ rowData: response.data });
			})
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.PhysicianGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.PhysicianGridExported
		};
		createAudit(auditData);
	};

	render() {
		let columnDefs = /salesperson|audit|customerserviceuser|salesadmin/.test(getUserRole().toLowerCase()) ? this.state.columnDefs.filter(x => x.headerName !== 'Actions') : this.state.columnDefs;
		return (
			<div className="clinic-contain">
				<PhysicianSearchMenu
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
					context={this.state.context}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									components={this.state.components}
									context={this.state.context}
									rowClassRules={this.state.rowClassRules}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicPhysicianGrid;
