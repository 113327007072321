import React, { Component, memo } from "react";
import { AgGridReact } from 'ag-grid-react';
import EditBtnCellRenderer from "./editBtnCellRenderer";
//service calls
import { getCompanyData } from "../../../../services/clinicPortalServices/companyServices";
import CompanySearchMenu from "./companySearchMenu";
import { getFullAddress, getUserDetails, getUserRole } from "../../../../services/common/util";
import { auditEnums } from "../../../../services/common/constants";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicCompanyGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			columnDefs: [
				{
					headerName: "Actions",
					minWidth: 100,
					maxWidth: 100,
					cellStyle: { textAlign: "center" },
					cellRenderer: "editBtnCellRenderer",
				},
				{
					headerName: "Company Name",
					field: "name",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Billing Address",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.address && params.data.address.address1) {
							return getFullAddress(params.data.address);
						} else {
							return "";
						}
					},
					cellRenderer: memo((params) => {
						return <span><i className="fas fa-map-marker-alt"></i> {params.value ? params.value : ''} + </span>;
					})
				},
				{
					headerName: "Name of Invoicing Contact",
					field: "contact_name",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Phone of Invoicing Contact",
					field: "contact_mobile",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Email of Invoicing Contact",
					field: "contact_email",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Associated Facilities(separated by comma)",
					minWidth: 150,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.facilityIds.length) {
							let facilityNames = [];
							params.data.facilityIds.map(facility => {
								facilityNames.push(facility.name);
								return null;
							})
							return facilityNames.join(', ');
						} else {
							return "";
						}
					},
				},
				{
					headerName: "Cost Per Order",
					minWidth: 150,
					field: "cost_per_order",
					resizable: true,
				},
				{
					headerName: "Invoice Processing",
					minWidth: 150,
					field: "invoice_processing",
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.invoice_processing) {
							return "YES";
						} else {
							return "NO";
						}
					},
				},
			],
			components: {
				editBtnCellRenderer: EditBtnCellRenderer,
			},

			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			context: { componentParent: this }
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		getCompanyData()
			.then((response) => {
				if (response.data && response.data.length) {
					this.setState({ rowData: response.data });
				} else {
					this.setState({ rowData: [] });
				}
			})
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.CompanyGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.CompanyGridExported
		};
		createAudit(auditData);
	};

	render() {
		let columnDefs = /salesperson|audit|salesadmin|customerserviceuser/.test(getUserRole().toLowerCase()) ? this.state.columnDefs.filter(x => x.headerName !== 'Actions') : this.state.columnDefs;
		return (
			<div className="clinic-contain">
				<CompanySearchMenu
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
					context={this.state.context}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									components={this.state.components}
									context={this.state.context}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicCompanyGrid;
