import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import ResultLinksSearchMenu from "./resultLinksSearchMenu";
//service calls
import { searchResultLinks } from "../../../../services/clinicPortalServices/orderEditService";
import PdfResultRenderer from "./pdfResultRenderer";
import moment from "moment";
import HistoryCellRenderer from "./historyCellRenderer";
import HistoryVisitedCellRenderer from "./historyVisitedCellRenderer";
import { dateComparator } from "../../../../services/common/util";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicResultLinksGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchFilters: {
				from_date: moment().startOf('week').format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
				dateRange: 'week'
			},
			columnDefs: [
				{
					headerName: "Action",
					minWidth: 150,
					field: "action",
					resizable: true,
					hide: true,
				},
				{
					headerName: "Access Code",
					minWidth: 200,
					field: "_id",
					resizable: true,
				},
				{
					headerName: "Created Date",
					field: "createdAt",
					minWidth: 200,
					resizable: true,
					valueGetter: (params) => {
						return moment(params.data.createdAt).format(
							"MM/DD/YYYY hh:mm:ss A"
						);
					},
					comparator: dateComparator
				},
				{
					headerName: "File",
					field: "pdf_path",
					minWidth: 150,
					resizable: true,
					cellRenderer: "pdfResultRenderer",
				},
				{
					headerName: "Managed History",
					field: "histories",
					minWidth: 100,
					resizable: true,
					cellRenderer: "historyCellRenderer",
				},
				{
					headerName: "Visited History",
					field: "histories",
					minWidth: 100,
					resizable: true,
					cellRenderer: "historyVisitedCellRenderer",
				},
				{
					headerName: "Is viewed",
					field: "is_viewed",
					minWidth: 150,
					resizable: true,
					valueGetter: function (params) {
						return params.value ? 'Yes' : 'No';
					},
				},
				{
					headerName: "Patient Name",
					field: "patient_name",
					minWidth: 200,
					resizable: true,
					valueGetter: function (params) {
						if (params.data.patient_id && params.data.patient_id.first_name) {
							return params.data.patient_id.first_name + " " + params.data.patient_id.last_name
						}
					},
				},
				{
					headerName: "Sample",
					field: "order_id.test_info.sample",
					minWidth: 200,
					resizable: true,
				},

			],
			components: {
				pdfResultRenderer: PdfResultRenderer,
				historyCellRenderer: HistoryCellRenderer,
				historyVisitedCellRenderer: HistoryVisitedCellRenderer
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true
			},
			rowData: [],
		};
	}
	getRowHeight = (params) => {
		if (params.data.update_count && params.data.update_count > 0) {
			return params.api.getSizesForCurrentTheme().rowHeight * (params.data.update_count + 1);
		} else {
			return false;
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadResultLinksGridData();
	};

	loadResultLinksGridData = () => {
		this.gridApi.showLoadingOverlay();
		const filter = {
			createdAt: {
				$gte: this.state.searchFilters.from_date,
				$lt: this.state.searchFilters.to_date,
			}
		}
		searchResultLinks(filter)
			.then((data) => {
				this.setState({ rowData: data });
				if (!data.length) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.gridApi.hideOverlay();
				}
			})
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		let filters = this.state.searchFilters;
		filters.from_date = moment().subtract(7, "days").format("YYYY-MM-DD") + 'T00:00';
		filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
		this.setState({ searchFilters: filters });

		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
		this.loadResultLinksGridData();
	};

	updateDateRange = (dateRange) => {
		const filters = this.state.searchFilters;
		if (filters.dateRange !== dateRange) {
			switch (dateRange) {
				case 'today':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				case 'week':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('week').format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				case 'month':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('month').format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				default:
					break;
			}
			this.setState({ searchFilters: filters });
			if (dateRange !== 'custom') {
				this.loadResultLinksGridData();
			}
		}
	}

	setDateRange = (dateFilter) => {
		if (moment(dateFilter.to_date).format("YYYY-MM-DD") === moment().add(1, "days").format("YYYY-MM-DD")) {
			if (moment(dateFilter.from_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
				dateFilter.dateRange = 'today';
			} else if (moment(dateFilter.from_date).format("YYYY-MM-DD") === moment().startOf('week').format("YYYY-MM-DD")) {
				dateFilter.dateRange = 'week';
			} else if (moment(dateFilter.from_date).format("YYYY-MM-DD") === moment().startOf('month').format("YYYY-MM-DD")) {
				dateFilter.dateRange = 'month';
			} else {
				dateFilter.dateRange = 'custom';
			}
		} else {
			dateFilter.dateRange = 'custom';
		}
	}

	handleDateFiltersChange = (dateTime, type) => {
		const filters = this.state.searchFilters;
		let filterIsValid = true;
		if (type === 'from_date') {
			if (moment(filters.to_date).isAfter(dateTime)) {
				filters.from_date = dateTime;
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(filters.from_date)) {
				filters.to_date = dateTime;
			} else {
				filterIsValid = false;
			}
		}
		if (filterIsValid) {
			this.setDateRange(filters)
			this.setState({ searchFilters: filters });
			this.loadResultLinksGridData();
		}
	}


	render() {
		return (
			<div className="clinic-contain">
				<ResultLinksSearchMenu
					handleDateFiltersChange={this.handleDateFiltersChange}
					updateDateRange={this.updateDateRange}
					from_date={this.state.searchFilters.from_date}
					to_date={this.state.searchFilters.to_date}
					date_range={this.state.searchFilters.dateRange}
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									components={this.state.components}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									getRowHeight={this.getRowHeight}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicResultLinksGrid;
