import React, { Component, memo } from "react";
import { AgGridReact } from 'ag-grid-react';
import EditBtnCellRenderer from "./editBtnCellRenderer";
//service calls
import SearchMenu from "./searchMenu";
import { getFullAddress, getUserDetails, dateComparator } from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { getAllInsuranceProviders } from "../../../../services/clinicPortalServices/insuranceProviderSerivce";
import moment from "moment";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicInsuranceProviderGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			columnDefs: [
				{
					headerName: "Actions",
					minWidth: 100,
					maxWidth: 100,
					cellStyle: { textAlign: "center" },
					cellRenderer: "editBtnCellRenderer",
				},
				{
					headerName: "Name",
					minWidth: 50,
					field: "name"
				},
				{
					headerName: "Code",
					minWidth: 50,
					field: "code"
				},
				{
					headerName: "Address",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.address && params.data.address.address1) {
							return getFullAddress(params.data.address)
						} else {
							return "";
						}
					},
					cellRenderer: memo(params => {
						return <span><i className="fas fa-map-marker-alt"></i> {params.value ? params.value : ""}</span>
					})
				},
				{
					headerName: "Phone",
					field: "phone",
					minWidth: 150,
					resizable: true,
					cellRenderer: memo(params => {
						return <span><i className="fas fa-phone-alt"></i> {params.data.phone ? params.data.phone : ""}</span>
					})
				},
				{
					headerName: "Last Modified Date",
					minWidth: 150,
					resizable: true,
					field: "modified_date",
					valueGetter: params => {
						if (params.data.modified_date) {
							return moment(params.data.modified_date, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")
						}
					},
					comparator: dateComparator
				},
				{
					headerName: "Created At",
					maxWidth: 200,
					field: "createdAt",
					valueGetter: (params) => {
						return moment.tz(params.data.createdAt, "America/New_York").format(
							"MM/DD/YYYY hh:mm:ss A"
						);
					},
					comparator: dateComparator
				},
				{
					headerName: "Updated At",
					maxWidth: 200,
					field: "updatedAt",
					valueGetter: (params) => {
						return moment.tz(params.data.updatedAt, "America/New_York").format(
							"MM/DD/YYYY hh:mm:ss A"
						);
					},
					comparator: dateComparator
				},
			],
			components: {
				editBtnCellRenderer: EditBtnCellRenderer,
			},

			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
				resizable: true
			},
			rowData: [],
			context: { componentParent: this }
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		getAllInsuranceProviders()
			.then((response) => {
				this.setState({ rowData: response.data });
			});
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.InsuranceProviderGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.InsuranceProviderGridExported
		};
		createAudit(auditData);
	};

	render() {
		return (
			<div className="clinic-contain">
				<SearchMenu
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
					context={this.state.context}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									components={this.state.components}
									context={this.state.context}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicInsuranceProviderGrid;
